import { DeepReadonly, ExoSession, Timer } from '@egzotech/exo-session';
import { Recordable } from '@egzotech/exo-session/features/common';
import { Logger } from '@egzotech/universal-logger-js';
import { Timers } from 'config/timers';
import { signal } from 'helpers/signal';
import { EMGProgramData, exerciseActionTracker } from 'libs/exo-session-manager/core';
import { ConnectedChannelToMuscle } from 'types';

import { CalibrationFlowStatesTypedData } from '../common/CalibrationFlow';
import EMGSignal from '../common/EMGSignal';
import { Recordings, SignalRecorderController } from '../common/SignalRecorderController';
import { TriggerableGroup } from '../common/TriggerableGroup';
import { DeviceType } from '../global/DeviceManager';
import { SettingsBuilder } from '../settings/SettingsBuilder';
import { EMGExerciseDefinition, GeneratedEMGExerciseDefinition } from '../types/GeneratedExerciseDefinition';

import { Exercise, ExerciseFeature } from './Exercise';
import { FinishedReason } from './FinishedReason';

export class EMGExercise implements Exercise {
  static readonly logger = Logger.getInstance('EMGExercise');
  readonly prepared = signal(false, 'EMGExercise.prepared');
  readonly finished = signal(false, 'EMGExercise.finished');
  private _programData: EMGProgramData;
  private _exerciseData = {
    totalDuration: signal(0, 'EMGExercise.totalDuration'),
  };
  private _calibrationData: DeepReadonly<CalibrationFlowStatesTypedData> | null = null;
  private _recordings: Recordings = {};
  private _recorderController: SignalRecorderController | null = null;
  private _settingsBuilder: SettingsBuilder;
  private _emgSignal: EMGSignal | null = null;
  private _exerciseDefinition: EMGExerciseDefinition | null = null;
  readonly triggerableGroup = new TriggerableGroup();
  private _timeInterval: NodeJS.Timeout | null = null;
  private _emgTimer = new Timer();

  constructor(
    readonly definition: GeneratedEMGExerciseDefinition,
    readonly exerciseName: string | null,
    readonly session: ExoSession,
    readonly deviceType: DeviceType,
  ) {
    this._settingsBuilder = new SettingsBuilder(definition);

    this._programData = {
      active: signal(false, 'EMGExercise._programData.active'),
      started: signal(false, 'EMGExercise._programData.started'),
      running: signal(false, 'EMGExercise._programData.running'),
      finishedReason: signal('exerciseFinished', 'EMGExercise._programData.finishedReason'),
      currentDuration: signal(0, 'EMGExercise._programData.currentDuration'),
    };
    this._exerciseDefinition = structuredClone(definition) as EMGExerciseDefinition;
  }
  get programData() {
    return this._programData;
  }

  get emgSignal() {
    return this._emgSignal;
  }

  get recordings() {
    return this._recordings;
  }

  get settings() {
    return this._settingsBuilder;
  }

  get exerciseData() {
    return this._exerciseData;
  }
  get connectedEMGChannels() {
    if (!this._calibrationData) {
      throw new Error('This exercise was never prepared');
    }
    const connectedChannelsToMuscles = this._calibrationData['connect-electrodes']?.connectedChannelsToMuscles as
      | ConnectedChannelToMuscle[]
      | undefined;

    return connectedChannelsToMuscles ? connectedChannelsToMuscles.map(ch => ch.channelIndex) : [];
  }

  private initializeInterval() {
    if (this._timeInterval !== null) {
      return;
    }

    this._timeInterval = setInterval(this.onTimeChange.bind(this), Timers.EXERCISE_DATA_REFRESH_INTERVAL);
  }

  init() {}

  private onTimeChange() {
    this._programData.currentDuration.value = this._emgTimer.duration;
  }

  prepare(calibrationData: DeepReadonly<CalibrationFlowStatesTypedData>): void {
    if (this._programData.active.peek()) {
      EMGExercise.logger.debug('start', 'Program is already started');
      return;
    }
    this._calibrationData = calibrationData;
    console.log(calibrationData, 'calibrtionDData');

    const recordables: Recordable<'single' | 'multi'>[] = [];

    if (!this._emgSignal) {
      this._emgSignal = EMGSignal.createFromCalibrationFlow(this.session, calibrationData, [this.triggerableGroup]);
      recordables.push(...this._emgSignal.getRecordables());
    }
    if (recordables.length > 0) {
      this._recorderController = new SignalRecorderController(recordables, this.connectedEMGChannels);
    }
    this.prepared.value = true;
    this._programData.active.value = true;
    this.finished.value = false;
    this.initializeInterval();
  }

  setProgram(): void {}

  supports(feature: ExerciseFeature): boolean {
    switch (feature) {
      case 'cable':
        return true;
      default:
        return false;
    }
  }

  play(): void {
    if (this.finished.peek()) {
      EMGExercise.logger.warn('play', 'Cannot play program that was finished');
      return;
    }
    if (!this._programData.active.peek()) {
      EMGExercise.logger.debug('play', 'Cannot play program that is ended or not yet started');
      return;
    }

    if (this._programData.running.peek()) {
      EMGExercise.logger.debug('play', 'Program is already running');
      return;
    }

    this._emgSignal?.enable(this._emgSignal.channels);

    if (this._recorderController?.started) {
      this._recorderController?.resume();
    } else {
      this._recorderController?.start();
    }

    this.initializeInterval();
    this._emgTimer.play();
    this._programData.started.value = true;
    this._programData.running.value = true;
  }

  pause(options?: { endPause?: boolean }): void {
    if (!this._programData.active.peek()) {
      EMGExercise.logger.warn('pause', 'Cannot pause program that is ended or not yet started');
      return;
    }
    if (!this._programData.running.peek()) {
      EMGExercise.logger.debug('play', 'Program is already stopped');
      return;
    }

    this._emgSignal?.disable();
    this._recorderController?.pause();
    if (!options?.endPause) {
      exerciseActionTracker.add('activity', 'pause');
    }
    this._programData.running.value = false;
    this._emgTimer.pause();
  }

  end(reason: FinishedReason = 'exerciseFinished'): void {
    this.pause({ endPause: true });

    if (!this._programData.active.peek()) {
      EMGExercise.logger.debug('end', 'Cannot end program that is not started');
      return;
    }
    if (this.finished.peek()) {
      EMGExercise.logger.debug('end', 'Program is already finished');
      return;
    }

    if (this._recorderController) {
      this._recordings = this._recorderController.stop();
    }
    this._emgTimer.pause();
    this._programData.finishedReason.value = reason;
    this._programData.running.value = false;
    this._programData.active.value = false;
    this.finished.value = true;
  }

  destroy(): void {
    this.end();
    this._emgSignal?.dispose();
    this._recorderController = null;
    if (this._timeInterval) {
      clearInterval(this._timeInterval);
    }
  }
}
