import { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import colors, { Channel } from 'config/theme/colors';
import { ReadonlySignal, useComputed, useSignal } from 'helpers/signal';

import { SVGBackgroundBar } from './SVGBackgroundBar';
import { SVGLevelBar } from './SVGLevelBar';
import { SVGSlider } from './SVGSlider';
import { SVGYAxis } from './SVGYAxis';

export interface EMGBarSizes {
  barWidth: number;
  svgYAxis: number;
  xOffset: number;
  containerWidthBase: string;
  containerWidth2xl: string;
}

interface Props {
  channel: number;
  currentValue: ReadonlySignal<number>;
  maxValue: ReadonlySignal<number>;
  thresholdLevel: number;
  onLevelChange?: (level: number) => void;
  isDisabled?: boolean;
  isThresholdDisabled?: boolean;
  color?: string;
  lightColor?: string;
  thresholdColor?: string;
  collapseThreshold?: boolean;
  narrow?: boolean;
  barIcon?: ReactNode;
  sizes?: EMGBarSizes;
}

// Below defined bar sizes for emg view exercise based on mock up.
export const barSizesWhen6Channels = {
  barWidth: 121,
  svgYAxis: 200,
  xOffset: 83,
  containerWidth2xl: '60',
  containerWidthBase: '60',
};
export const barsSizesBelow6Channels = {
  barWidth: 121,
  svgYAxis: 210,
  xOffset: 83,
  containerWidth2xl: '64',
  containerWidthBase: '64',
};

export const EMGBar = memo(
  ({
    channel,
    currentValue,
    maxValue,
    onLevelChange,
    thresholdLevel,
    isDisabled,
    isThresholdDisabled,
    color,
    lightColor,
    thresholdColor,
    collapseThreshold,
    narrow,
    barIcon,
    sizes,
  }: Props) => {
    useSignals();
    const [currentMaxValue, setCurrentMaxValue] = useState(maxValue.value);
    const [isRescaling, setIsRescaling] = useState(false);
    const maxValueRef = useRef(currentMaxValue);
    const primaryColor = color ?? colors.channel[(channel + 1) as Channel];
    const primaryLightColor = lightColor ?? colors.channel.light[(channel + 1) as Channel];

    const updateCurrentMax = useCallback(() => {
      setCurrentMaxValue(v => {
        const delta = v - maxValueRef.current;
        return Math.abs(delta) < 1 ? maxValueRef.current : maxValueRef.current + delta / 2;
      });
    }, []);

    useEffect(() => {
      if (!isRescaling || isDisabled) {
        return;
      }
      const frameId = requestAnimationFrame(updateCurrentMax);

      return () => {
        cancelAnimationFrame(frameId);
      };
    }, [isRescaling, currentMaxValue, updateCurrentMax, isDisabled]);

    useEffect(() => {
      setIsRescaling(maxValue.value !== currentMaxValue);
      maxValueRef.current = maxValue.value;
    }, [currentMaxValue, maxValue.value]);

    // These values strictly reflects dimensions in mockup
    // yHeight is vertical dimenstion of the bar and
    // ySpace is space between top of SVG and the bar beginning
    const yHeight = 438.4;
    const ySpace = 33.5;
    const defaultBackgroundLevelBar = useSignal(100, 'EMGBar.defaultBackgroundLevelBar');
    const svgBackgroundBar = useComputed(() => {
      const value = Math.abs(currentValue.value);
      return maxValue.value ? (value / maxValue.value) * 100 : 0;
    }, 'EMGBar.svgBackgroundBar');

    const isSliderCollapsed = collapseThreshold && isThresholdDisabled;

    const barWidth = sizes ? sizes.barWidth : narrow ? 46 : 115;
    const barReduction = 115 - barWidth;
    // viewBox="min-x min-y width height"
    const viewBox = useBreakpointValue(
      narrow
        ? {
            base: `-32 0 ${176 + 32} 490`,
            '2xl': `-32 0 ${176 + 32} 490`,
          }
        : sizes
        ? {
            base: `-32 0 ${256 + 32} 490`,
            '2xl': `-2 0 ${256 + 32} 490`,
          }
        : {
            base: `-32 0 ${256 + 32} 490`,
            '2xl': `-32 0 ${256 + 32} 490`,
          },
    );

    const width = narrow
      ? { base: '36', '2xl': '52' }
      : sizes
      ? { base: sizes.containerWidthBase, '2xl': sizes.containerWidth2xl }
      : { base: '56', '2xl': '72' };

    return (
      <Box w={width} boxSizing="content-box" pr={narrow ? '3' : '0'}>
        <svg viewBox={viewBox} style={sizes ? { overflow: 'visible' } : {}}>
          {!isDisabled && (
            <SVGYAxis
              width={sizes ? sizes.svgYAxis + 40 : barWidth + 42}
              yAxisMaxNoLines={10}
              maxValue={(currentMaxValue * (yHeight + ySpace)) / yHeight}
              yHeight={ySpace + yHeight}
            />
          )}
          <g>
            <SVGBackgroundBar
              width={barWidth}
              xOffset={sizes ? sizes.xOffset : 56}
              yOffset={ySpace}
              yHeight={yHeight}
              level={defaultBackgroundLevelBar}
              color={isDisabled ? colors.gray[300] : primaryLightColor}
            />
            {!isDisabled && (
              <SVGBackgroundBar
                width={barWidth}
                xOffset={sizes ? sizes.xOffset : 56}
                yOffset={ySpace}
                yHeight={yHeight}
                level={svgBackgroundBar}
                color={primaryColor}
              />
            )}
            <g transform={narrow ? 'translate(54 217)' : 'translate(89 217)'}>{barIcon}</g>
          </g>
          {!isDisabled && !isThresholdDisabled && (
            <SVGLevelBar
              width={196 - barReduction}
              xOffset={29}
              yOffset={ySpace}
              yHeight={yHeight}
              level={thresholdLevel}
            />
          )}
          {!isSliderCollapsed && (
            <SVGSlider
              level={thresholdLevel}
              xOffset={(narrow ? 177 : 192) - barReduction}
              yOffset={ySpace}
              yHeight={yHeight}
              color={thresholdColor ?? primaryColor}
              onLevelChange={onLevelChange}
              isDisabled={isDisabled || isThresholdDisabled}
            />
          )}
        </svg>
      </Box>
    );
  },
);

EMGBar.displayName = 'EMGBar';
