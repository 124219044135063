import { EventBus } from '@egzotech/exo-session';
import { Logger } from '@egzotech/universal-logger-js';

import { getPredefinedMeissaOtCAMExercises } from '../definitions/meissa-ot/cam-exercises';
import { getPredefinedMeissaOtCAMTurnKeyExercises } from '../definitions/meissa-ot/cam-turn-key-exercise';
import { getPredefinedMeissaOtCPMEMGExercises } from '../definitions/meissa-ot/cpm-emg-exercises';
import { getPredefinedMeissaOtCPMEMSEMGExercises } from '../definitions/meissa-ot/cpm-ems-emg-exercises';
import { getPredefinedMeissaOtCPMEMSExercises } from '../definitions/meissa-ot/cpm-ems-exercises';
import { getPredefinedMeissaOtCPMExercises } from '../definitions/meissa-ot/cpm-exercises';
import { getPredefinedMeissaOtCPMForceExercises } from '../definitions/meissa-ot/cpm-force-exercises';
import { getPredefinedMeissaOTGameCosmicMissionExercises } from '../definitions/meissa-ot/games/cosmic-mission-exercises';
import { getPredefinedMeissaOTGameDrivingGameExercises } from '../definitions/meissa-ot/games/driving-game-exercises';
import { getPredefinedSidraLegCAMExercises } from '../definitions/sidra-leg/cam-exercises';
import { getPredefinedSidraLegCPMEMGExercises } from '../definitions/sidra-leg/cpm-emg-exercises';
import { getPredefinedSidraLegCPMEMSEMGExercises } from '../definitions/sidra-leg/cpm-ems-emg-exercises';
import { getPredefinedSidraLegCPMEMSExercises } from '../definitions/sidra-leg/cpm-ems-exercises';
import { getPredefinedSidraLegCPMExercises } from '../definitions/sidra-leg/cpm-exercises';
import { getPredefinedSidraLegGameCosmicMissionExercises } from '../definitions/sidra-leg/games/cosmic-mission-exercises';
import { getPredefinedSidraLegGameDrivingGameExercises } from '../definitions/sidra-leg/games/driving-game-exercises';
import { getPredefinedStellaBioEMGExercise } from '../definitions/stella-bio/emg-exercise';
import { getPredefinedStellaBioEMSEMGExercise } from '../definitions/stella-bio/ems-emg-exercise';
import { getPredefinedStellaBioEMSExercise } from '../definitions/stella-bio/ems-exercise';
import { GeneratedExerciseDefinition } from '../types/GeneratedExerciseDefinition';

export type ExerciseSelectorEvents = {
  onSelectedExerciseChange: (payload: string | null) => void;
};

export class ExerciseSelector {
  private readonly _exercises: Record<string, GeneratedExerciseDefinition> = {};
  private _selectedExerciseId: string | null = null;

  static readonly logger = Logger.getInstance('ExerciseSelector');

  readonly events: EventBus<ExerciseSelectorEvents> = new EventBus();

  constructor() {
    this.initializeExercises();
  }

  get exercises() {
    return this._exercises;
  }

  get selectedExerciseId() {
    return this._selectedExerciseId;
  }

  get selectedExercise() {
    if (!this._selectedExerciseId) {
      return null;
    }
    if (!this._exercises[this._selectedExerciseId]) {
      ExerciseSelector.logger.debug('selectedExercise getter', 'Selected exercise does not exist');
      return null;
    }
    return this._exercises[this._selectedExerciseId];
  }

  get isValidExercise() {
    return !this._selectedExerciseId || Boolean(this._selectedExerciseId && this._exercises[this._selectedExerciseId]);
  }

  selectExercise(exerciseId: string) {
    if (exerciseId === this._selectedExerciseId) {
      ExerciseSelector.logger.info('selectExercise', `Exercise ${exerciseId} is already selected`);
      return;
    }
    this._selectedExerciseId = exerciseId;
    EventBus.raise(this.events, 'onSelectedExerciseChange', exerciseId);
  }

  endExercise() {
    if (!this._selectedExerciseId) {
      ExerciseSelector.logger.info('endExercise', `Exercise is already ended`);
      return;
    }
    this._selectedExerciseId = null;
    EventBus.raise(this.events, 'onSelectedExerciseChange', null);
  }

  private initializeExercises() {
    const exercisesList = {
      ...getPredefinedSidraLegCPMExercises(),
      ...getPredefinedSidraLegCAMExercises(),
      ...getPredefinedSidraLegCPMEMGExercises(),
      ...getPredefinedSidraLegCPMEMSExercises(),
      ...getPredefinedSidraLegCPMEMSEMGExercises(),
      ...getPredefinedSidraLegGameCosmicMissionExercises(),
      ...getPredefinedSidraLegGameDrivingGameExercises(),
      ...getPredefinedMeissaOtCPMExercises(),
      ...getPredefinedMeissaOtCPMEMGExercises(),
      ...getPredefinedMeissaOtCPMForceExercises(),
      ...getPredefinedMeissaOtCPMEMSExercises(),
      ...getPredefinedMeissaOtCPMEMSEMGExercises(),
      ...getPredefinedMeissaOtCAMExercises(),
      ...getPredefinedMeissaOtCAMTurnKeyExercises(),
      ...getPredefinedMeissaOTGameCosmicMissionExercises(),
      ...getPredefinedMeissaOTGameDrivingGameExercises(),
      ...getPredefinedStellaBioEMSExercise(),
      ...getPredefinedStellaBioEMSEMGExercise(),
      ...getPredefinedStellaBioEMGExercise(),
    };

    Object.assign(this._exercises, exercisesList);
  }
}
