import {
  DeepReadonly,
  EventBus,
  ExoSession,
  ExoSessionCandidate,
  ExoSessionDummyManager,
  ExoSessionFactory,
  ExoSessionFeature,
  ExoSessionStatus,
  MeissaOTDummyController,
  PredefinedOptionsForDevice,
  SidraLEGDummyController,
  StellaBIODummyController,
} from '@egzotech/exo-session';
import { ExoSessionError } from '@egzotech/exo-session/dist/src/ExoSessionError';
import { ExoBatteryFeature } from '@egzotech/exo-session/features/battery';
import { CableType, ChannelConnectionQuality, ExoCableFeature } from '@egzotech/exo-session/features/cable';
import { ExoEmergencyButtonFeature } from '@egzotech/exo-session/features/emergency-button';
import { ExoExtensionFeature } from '@egzotech/exo-session/features/extension';
import {
  ExoModuleIdentification,
  ExoModuleIdentificationFeature,
} from '@egzotech/exo-session/features/module-identification';
import { ExoMotorFeature } from '@egzotech/exo-session/features/motor';
import { Logger } from '@egzotech/universal-logger-js';
import { __ } from 'helpers/i18n';
import { logger } from 'helpers/logger';
import { signal } from 'helpers/signal';
import { includesAnyString } from 'helpers/string';

import { notification } from 'components/common/Notification';

import { DirectHubMessageSender } from '../../../../services/DirectHubMessageSender';
import { calculateBatteryLevel } from '../common/calculations';
import { exerciseActionTracker } from '..';

import { LocalStorageManager } from './LocalStorageManager';
import {
  MeissaOTModuleId,
  meissaOTModulesVersionConfiguration,
  SidraLegModuleId,
  sidraLegModulesVersionConfiguration,
} from './modules-configurations';

export type DeviceType = 'sidra-leg' | 'meissa-ot' | 'stella-bio' | 'unspecified';
export type CableStatus = 'attached' | 'detached';
export type MeissaOTExtensionType = keyof PredefinedOptionsForDevice['meissa-ot']['extension']['types'] | null;
export type SidraLegExtensionType = 'passive' | 'flexion' | 'rotation';
export type ExtensionType = MeissaOTExtensionType | SidraLegExtensionType;
export type MotorPlacementFreeCouplingPresets = {
  main: keyof PredefinedOptionsForDevice['meissa-ot']['motor']['main']['freeRoamCoupling'];
  knee: keyof PredefinedOptionsForDevice['sidra-leg']['motor']['knee']['freeRoamCoupling'];
  ankle: keyof PredefinedOptionsForDevice['sidra-leg']['motor']['ankle']['freeRoamCoupling'];
};

export interface Device {
  /**
   * The id that is propagated over the network or passed over the cable
   */
  id: string;
  /**
   * Displayed name, eg. VSZONYOA
   */
  displayName: string;
  type: DeviceType;
  connectionStatus: ExoSessionStatus;
  updatedDummyFromLocalStorage: boolean;
  session: ExoSession | null;
  candidate: ExoSessionCandidate;
  channelsConnectionQuality: Record<number, ChannelConnectionQuality>;
  lastTimeUsed?: number;
  cableStatus: CableStatus;
  cable?: ExoCableFeature;
  moduleIdentification?: ExoModuleIdentificationFeature[];
  extension?: ExoExtensionFeature;
  cableType?: DeepReadonly<CableType>;
  extensionType?: ExtensionType;
  emergencyButton?: ExoEmergencyButtonFeature;
  battery?: ExoBatteryFeature;
  /**
   * If available measured in percentage [0-100]
   */
  batteryLevel?: number;
  otherFeatures?: ExoSessionFeature[];
}

export type LocalStorageDevice = Pick<Device, 'id' | 'type' | 'lastTimeUsed' | 'displayName'> &
  Partial<Pick<Device, 'channelsConnectionQuality' | 'cableType' | 'extensionType'>>;

export type DeviceFirmwareModule = {
  moduleId: SidraLegModuleId | MeissaOTModuleId;
  identification: ExoModuleIdentification;
  isMissing: boolean;
};

export interface DeviceState {
  candidates: ExoSessionCandidate[];
  storedCandidates: Record<string, LocalStorageDevice>;
  selectedDeviceId: Device['id'] | null;
  wantConnectDeviceId: Device['id'] | null;
  initialized: boolean;
  active: boolean;
  firmwareModules: { [key in SidraLegModuleId | MeissaOTModuleId]?: DeviceFirmwareModule };
  connectionError?: ExoSessionError;
  isTared: boolean;
}

/**
 *  Defaults to SidraLEGDummyController
 */
type DummyController = SidraLEGDummyController | MeissaOTDummyController | StellaBIODummyController;

export type DeviceEvents = {
  /**
   *  Batch event that is fired on activate method
   */
  onDeviceActivate: () => void;
  /**
   *  Batch event that is fired on init method
   */
  onDeviceInit: () => void;
  /**
   *  Batch event that is fired on destroy method
   */
  onDeviceDestroy: () => void;
  /**
   *  Event that is fired when selected device changes
   */
  onDeviceChange: (payload: Device) => void;
  /**
   *  Event that is fired when list of available candidates changes
   */
  onCandidatesChange: (payload: DeviceState['candidates']) => void;
  /**
   *  Event that is fired when list of stored candidates changes
   */
  onStoredCandidatesChange: (payload: DeviceState['storedCandidates']) => void;
  /**
   *  Event that is fired when id of selected device changes
   */
  onSelectedDeviceIdChange: (payload: DeviceState['selectedDeviceId']) => void;
  /**
   *  Event that is fired when id of autoconnecting device changes
   */
  onWantConnectDeviceIdChange: (payload: DeviceState['wantConnectDeviceId']) => void;
  /**
   *  Event that is fired when active flag for device changes
   */
  onDeviceActiveChange: (payload: DeviceState['active']) => void;
  /**
   *  Event that is fired when connection error occurs or is dismissed
   */
  onDeviceConnectionErrorChange: (payload: DeviceState['connectionError']) => void;
  /**
   *  Event that is fired when firmware module is connected or has changed
   */
  onModuleIdentify: (payload: Omit<DeviceFirmwareModule, 'isMissing'>) => void;
  /**
   *  Event that is fired when firmware module is lost
   */
  onModuleLost: (moduleId: string) => void;
  /**
   *  Event that is fired when device is tared
   */
  onTareDevice: () => void;
};

let createWorker: () => Worker = () => {
  throw new Error('Function not loaded');
};

// When testing we cannot load 'createWorker' because jest will fail on 'import.meta'.
if (process.env.NODE_ENV !== 'test') {
  import('./createWorker').then(v => (createWorker = v.createWorker));
}

export class DeviceManager {
  /**
   * PEC Standard deviation is stored in device memory which we can get asynchronously using `getPECStandardDeviation` method from `ExoSensorForceFeature`. This property is used to retrieve it synchronously during runtime if PEC Calibration was already performed.
   */
  static pecStandardDeviation = signal<number | undefined>(undefined, 'DeviceManager.pecStandardDeviation');
  static makeDummyPECCalibrationFailing = signal(false, 'DeviceManager.makeDummyPECCalibrationFailing');

  private readonly DEVICE_SCAN_TIME = 3000;

  private devices: Record<string, Device>;
  private _deviceState: DeviceState;
  private scanLock: boolean;

  private _dummyController: DummyController | null = null;
  private _sessionFactory: ExoSessionFactory | null = null;
  private _dummyManager: ExoSessionDummyManager | null = null;
  private deviceScanTime = 0;
  private config?: {
    [k: string]:
      | {
          version: ExoModuleIdentification['version'];
        }
      | undefined;
  };

  static readonly logger = Logger.getInstance('DeviceManager');

  readonly events: EventBus<DeviceEvents> = new EventBus();

  get initialized() {
    return this._deviceState.initialized;
  }

  get selectedDevice() {
    if (!this._deviceState.selectedDeviceId) {
      return null;
    }

    if (!this.devices[this._deviceState.selectedDeviceId]) {
      DeviceManager.logger.debug('selectedDevice getter', 'Selected device does not exist');
      return null;
    }

    return this.devices[this._deviceState.selectedDeviceId];
  }

  get session() {
    if (!this.selectedDevice) {
      DeviceManager.logger.debug('session getter', 'No selected device');
      return null;
    }

    return this.selectedDevice.session;
  }

  get state() {
    return this._deviceState;
  }

  constructor() {
    this.devices = {};
    this._deviceState = {
      candidates: [],
      storedCandidates: {},
      initialized: false,
      selectedDeviceId: null,
      wantConnectDeviceId: null,
      active: false,
      firmwareModules: {},
      isTared: false,
    };
    this.scanLock = false;
  }

  isConnectionRestored() {
    return (
      !!this.session?.isValid &&
      !!this.selectedDevice &&
      !includesAnyString(this.selectedDevice.connectionStatus, ['disposed', 'disconnected', 'connected-idle'])
    );
  }

  init(isDummy: boolean) {
    if (this._sessionFactory) {
      this._sessionFactory.dispose();
    }

    // We only use worker outside of tests currently
    const workerFactory =
      process.env.NODE_ENV !== 'test'
        ? () => {
            const worker = createWorker();

            return {
              port: worker,
              terminate: () => worker.terminate(),
            };
          }
        : null;

    if (isDummy) {
      this._dummyManager = new ExoSessionDummyManager();
      this._dummyManager.addCandidate('DUMMY-SIDRA-LEG-1', 'sidra-leg', 'D-SIDRA-1', {
        modules: sidraLegModulesVersionConfiguration.modules,
      });
      this._dummyManager.addCandidate('DUMMY-SIDRA-LEG-2', 'sidra-leg', 'D-SIDRA-2', {
        modules: sidraLegModulesVersionConfiguration.modules,
      });
      this._dummyManager.addCandidate('DUMMY-SIDRA-LEG-3', 'sidra-leg', 'SIDRA-INCOMPAT');
      this._dummyManager.addCandidate('DUMMY-MEISSA-OT-1', 'meissa-ot', 'D-MEISSA-1', {
        modules: meissaOTModulesVersionConfiguration.modules,
      });
      this._dummyManager.addCandidate('DUMMY-MEISSA-OT-2', 'meissa-ot', 'D-MEISSA-2', {
        modules: meissaOTModulesVersionConfiguration.modules,
      });
      this._dummyManager.addCandidate('DUMMY-MEISSA-OT-3', 'meissa-ot', 'MEISSA-INCOMPAT');
      this._dummyManager.addCandidate('DUMMY-STELLA-BIO-1', 'stella-bio', 'D-STELLA-1');
      this._dummyManager.addCandidate('DUMMY-STELLA-BIO-2', 'stella-bio', 'D-STELLA-2');

      const directHubMessageSender = DirectHubMessageSender.getInstance();

      this._sessionFactory = new ExoSessionFactory({
        dummyProvider: this._dummyManager,
        workerFactory,
        directHubProvider: directHubMessageSender.directHubManager,
      });
      DeviceManager.logger.info('init', 'Creating factory for dummy devices');
    } else {
      const directHubMessageSender = DirectHubMessageSender.getInstance();
      this._sessionFactory = new ExoSessionFactory({
        workerFactory,
        ...(process.env.REACT_APP_CAN2WS_URL ? { can2WsUrl: process.env.REACT_APP_CAN2WS_URL } : {}),
        directHubProvider: directHubMessageSender.directHubManager,
      });
      DeviceManager.logger.info('init', 'Creating factory for real devices');
    }

    const lastUsedDevice = this.getLastUsedDevice(isDummy);

    if (lastUsedDevice) {
      this._deviceState.wantConnectDeviceId = lastUsedDevice.id;
      EventBus.raise(this.events, 'onWantConnectDeviceIdChange', lastUsedDevice.id);
    }

    this.deviceScanTime = 0;
    this._deviceState.initialized = true;
    this._deviceState.active = false;
    EventBus.raise(this.events, 'onDeviceInit');
  }

  destroy() {
    this._deviceState = {
      candidates: [],
      storedCandidates: {},
      initialized: false,
      selectedDeviceId: null,
      wantConnectDeviceId: null,
      active: false,
      firmwareModules: {},
      isTared: false,
    };

    if (this._sessionFactory) {
      this._sessionFactory.dispose();
      this._sessionFactory = null;
    }
    if (this._dummyManager) {
      this._dummyManager = null;
    }

    EventBus.raise(this.events, 'onDeviceDestroy');
  }

  activate() {
    if (!this._deviceState.initialized) {
      DeviceManager.logger.warn('activate', 'Trying to activate the device manager before initialization.');
      return;
    }

    if (!this._sessionFactory) {
      throw new Error('Cannot activate manager without initialization');
    }

    if (this._deviceState.active) {
      return;
    }

    this._deviceState.active = true;
    EventBus.raise(this.events, 'onDeviceActivate');

    this.startScan();
  }

  deactivate() {
    this._deviceState.active = false;
    EventBus.raise(this.events, 'onDeviceActiveChange', false);
  }

  autoConnect(toId?: string) {
    if (toId) {
      this._deviceState.wantConnectDeviceId = toId;
    }

    if (!this._deviceState.wantConnectDeviceId) {
      DeviceManager.logger.info('autoConnect', 'No device id for autoconnect is specified');
      return;
    }

    const candidate = this._deviceState.candidates.find(v => v.id === this._deviceState.wantConnectDeviceId);

    if (!candidate) {
      DeviceManager.logger.warn('autoConnect', 'Device is not listed in available devices');
      return;
    }

    this.selectDevice(this._deviceState.wantConnectDeviceId);

    this._deviceState.wantConnectDeviceId = null;
    EventBus.raise(this.events, 'onWantConnectDeviceIdChange', null);
  }

  setTareDevicaStatus() {
    EventBus.raise(this.events, 'onTareDevice');
  }

  async requestCandidate() {
    if (!this._deviceState.active) {
      DeviceManager.logger.debug('requestCandidate', 'Device is not yet activated');
      return;
    }

    if (!this._sessionFactory) {
      DeviceManager.logger.debug('requestCandidate', 'Device session is not yet created');
      return;
    }

    await this._sessionFactory.requestCandidate();
  }

  async selectDevice(id: Device['id']) {
    if (this._deviceState.selectedDeviceId !== id) {
      if (this._deviceState.selectedDeviceId) {
        await this.disposeSelectedDevice();
      }

      this._deviceState.selectedDeviceId = id;
      EventBus.raise(this.events, 'onSelectedDeviceIdChange', id);
    } else {
      return;
    }

    const candidate = this._deviceState.candidates.find(v => v.id === id);

    if (!candidate) {
      throw new Error(`Cannot select device with '${id}'. There is no candidate for the device.`);
    }

    this.deactivate();

    this.getConfig(candidate.deviceType as DeviceType);

    await this.startSession(id);

    if (!this.selectedDevice) {
      throw new Error(`Device with '${id}' could not be selected`);
    }

    EventBus.raise(this.events, 'onDeviceChange', this.selectedDevice);

    this.selectedDevice.lastTimeUsed = Date.now();
    this.saveDeviceDataToLocalStorage(this.selectedDevice);
    this.updateDummyControllerFromLocalStorage(id);
    DeviceManager.logger.debug('selectDevice', `Device ${id} is selected`);

    if (this.isDeviceDummy(this.selectedDevice.id)) {
      this.initDummyController();
    }
  }

  private getConfig(deviceType = this.selectedDevice?.type) {
    if (deviceType === 'sidra-leg') {
      this.config = sidraLegModulesVersionConfiguration.modules;
    }
    if (deviceType === 'meissa-ot') {
      this.config = meissaOTModulesVersionConfiguration.modules;
    }
    if (deviceType === 'stella-bio') {
      this.config = {};
    }
    if (!this.config) {
      throw new Error('Configuration for firmware modules not found');
    }
    return this.config;
  }

  isDeviceDummy = (id: Device['id']) => id.startsWith('dummy:');

  getDummyDeviceModules() {
    if (!this._dummyManager) {
      DeviceManager.logger.warn('getDummyDeviceModules', 'Dummy manager is not initialized');
      return null;
    }
    if (!this._deviceState.selectedDeviceId) {
      DeviceManager.logger.warn('getDummyDeviceModules', 'Device is not selected');
      return null;
    }
    if (!this._dummyController) {
      return null;
    }

    return this._dummyManager.getDummyModules(this._deviceState.selectedDeviceId);
  }

  getDummyController() {
    return this._dummyController;
  }

  getLastUsedDevice(isDummy: boolean): LocalStorageDevice | null {
    const localStorageData = localStorage.getItem('devices');
    if (localStorageData) {
      const devices: Record<string, LocalStorageDevice> = JSON.parse(localStorageData);

      this._deviceState.storedCandidates = devices;
      localStorage.setItem('devices', JSON.stringify(devices));

      const sortedList = Object.keys(devices)
        .map(id => ({
          id,
          type: devices[id].type,
          displayName: devices[id].displayName,
          lastTimeUsed: devices[id].lastTimeUsed!,
          channelsConnectionQuality: devices[id].channelsConnectionQuality,
          cableType: devices[id]?.cableType,
        }))
        .sort((a, b) => {
          if (a.lastTimeUsed < b.lastTimeUsed) {
            return 1;
          }
          if (a.lastTimeUsed > b.lastTimeUsed) {
            return -1;
          }
          return 0;
        })
        .filter(device => isDummy === this.isDeviceDummy(device.id));
      if (sortedList.length) {
        DeviceManager.logger.debug('getLastUsedDevice', 'Device: ', sortedList[0]);
        return sortedList[0];
      }
    }
    return null;
  }

  resetConnectionError() {
    if (!this.selectedDevice?.session) {
      throw new Error('No session with device');
    }

    this.selectedDevice.session.control({ execute: { resetController: {} } });
  }

  private startScan() {
    if (this.scanLock) {
      return;
    }

    this.scan();
  }

  private scan() {
    this.scanLock = false;

    if (!this._sessionFactory) {
      throw new Error('Cannot start scanning without initialization');
    }

    if (!this._deviceState.active) {
      return;
    }

    DeviceManager.logger.debug('initScanner', 'Scanning devices');

    this.scanLock = true;
    this._sessionFactory.getCandidates({ scanTime: this.deviceScanTime }).then(candidates => {
      this._deviceState.candidates = candidates;

      EventBus.raise(this.events, 'onCandidatesChange', candidates);
      this.autoConnect();
      this.scan();
    });

    if (this.deviceScanTime !== this.DEVICE_SCAN_TIME) {
      // First immediate scan after init use 0 as scan time, the change it to DEVICE_SCAN_TIME
      this.deviceScanTime = this.DEVICE_SCAN_TIME;
    }
  }

  private initDummyController() {
    if (!this._dummyManager || !this._deviceState.selectedDeviceId) {
      DeviceManager.logger.debug(
        'initDummyController',
        'Cannot initialize dummy controller, no device is selected or dummy manager is not yet defined',
      );
      return;
    }

    if (!this.isDeviceDummy(this._deviceState.selectedDeviceId)) {
      DeviceManager.logger.debug(
        'initDummyController',
        `Selected device ${this._deviceState.selectedDeviceId} is not a dummy device`,
      );
      return;
    }

    if (!this.selectedDevice) {
      DeviceManager.logger.debug('initDummyController', 'No selected device');
      return;
    }

    if (this.selectedDevice.type === 'unspecified') {
      DeviceManager.logger.debug('initDummyController', 'Device type is unspecified');
      return;
    }

    try {
      this.setDummyController(
        this._dummyManager.getController(this._deviceState.selectedDeviceId, this.selectedDevice.type),
      );
      DeviceManager.logger.info('initDummyController', 'Dummy controller created');
    } catch (e) {
      DeviceManager.logger.error('initDummyController', 'Dummy controller error: ', e);
    }
  }

  private setDummyController(
    dummyController: SidraLEGDummyController | MeissaOTDummyController | StellaBIODummyController,
  ) {
    this._dummyController = dummyController;
  }

  private destroyDummyController() {
    if (this._dummyController) {
      this._dummyController = null;
    }
  }

  private async startSession(id: Device['id']) {
    if (!this._sessionFactory) {
      throw new Error(`Session for device ${id} cannot be started, session factory is not defined`);
    }

    const candidate = this._deviceState.candidates.find(v => v.id === id);

    if (!candidate) {
      throw new Error(`Session for device ${id} cannot be started, device candidate is not available`);
    }

    const device = {
      candidate,
      displayName: candidate.displayName,
      channelsConnectionQuality: {},
      connectionStatus: 'connected',
      id: candidate.id,
      session: null,
      type: candidate.deviceType,
      updatedDummyFromLocalStorage: false,
    } as Device;

    device.session = await this._sessionFactory.createSession(candidate);

    const modulesIds = Object.keys(candidate.options['module-identification']?.modules ?? {});

    device.moduleIdentification = device.session.features.includes('module-identification')
      ? modulesIds.map(name => {
          if (!device.session) {
            throw new Error('Cannot activate feature without session');
          }

          return device.session.activate(ExoModuleIdentificationFeature, {
            name,
          });
        })
      : [];

    const moduleIdentificationHandler = (feature: ExoModuleIdentificationFeature, curr: ExoModuleIdentification) => {
      logger.info(
        'DeviceManager.moduleIdentificationHandler',
        `Idenitfied new firmware module '${feature.moduleName}'`,
        curr,
      );

      EventBus.raise(this.events, 'onModuleIdentify', {
        moduleId: feature.moduleName,
        identification: curr,
      });
    };

    device.moduleIdentification.forEach(v => (v.onChange = (_, curr) => moduleIdentificationHandler(v, curr)));
    device.moduleIdentification.forEach(v => (v.onIdentify = curr => moduleIdentificationHandler(v, curr)));
    device.moduleIdentification.forEach(
      v =>
        (v.onLost = () => {
          logger.warn('DeviceManager.onLost', `Lost firmware module '${v.moduleName}'`);

          if (LocalStorageManager.payload.disableModuleIdentificationOnLost.value) {
            return true;
          }

          EventBus.raise(this.events, 'onModuleLost', v.moduleName);
        }),
    );

    device.session.onStatus = status => {
      if (!this.selectedDevice) {
        DeviceManager.logger.warn('session.onStatus', 'Cannot update device status, there is no selected device.');
        return;
      }

      DeviceManager.logger.info('session.onStatus', 'onStatus', status);

      this.selectedDevice.connectionStatus = status;
      if (status === 'disconnected' || status === 'disposed' || status === 'connected-idle') {
        this.selectedDevice.batteryLevel = undefined;
      }

      this.notifyDeviceUpdate();
    };

    let clearErrorTimeout: ReturnType<typeof setInterval> | undefined;

    device.session.onError = error => {
      DeviceManager.logger.warn('session.onError', 'Error has occured for the sesssion', error);

      // TODO: How to handle showing errors in production? Currently we only show them in console for
      // development purposes
      // if (this._deviceState.connectionError?.id !== error.id) {
      //   notification('Session error: ' + error.id + ', ' + error.reason, 'warning');
      // }

      this._deviceState.connectionError = error;
      EventBus.raise(this.events, 'onDeviceConnectionErrorChange', error);

      if (clearErrorTimeout) {
        clearTimeout(clearErrorTimeout);
      }

      clearErrorTimeout = setTimeout(() => {
        this._deviceState.connectionError = undefined;
        EventBus.raise(this.events, 'onDeviceConnectionErrorChange', undefined);
      }, 5000);
    };

    if (device.type === 'stella-bio') {
      device.battery = device.session.activate(ExoBatteryFeature);

      DeviceManager.logger.info('startSession', `ExoBatteryFeature for device ${id} is activated`);

      device.battery.onChange = () => {
        if (!this.selectedDevice) {
          DeviceManager.logger.warn('deviceInfo.onChange', 'onChange', 'No selected device');
          return;
        }

        if (!this.selectedDevice.battery?.voltage) {
          DeviceManager.logger.warn('deviceInfo.onChange', 'onChange', 'Battery voltage is unknown');
          return;
        }

        this.selectedDevice.batteryLevel = calculateBatteryLevel(this.selectedDevice.battery.voltage);
        this.notifyDeviceUpdate();
      };
    }

    device.cable = device.session.activate(ExoCableFeature);
    DeviceManager.logger.info('startSession', `CableFeature for device ${id} is activated`);

    if (device.session.features.includes('extension')) {
      device.extension = device.session.activate(ExoExtensionFeature);
      const extensionMap = Object.entries(device.session.options.extension?.types ?? {}).reduce((acc, [key, value]) => {
        acc[value.id] = key;
        return acc;
      }, {} as Record<number, string>);

      device.extension.onExtensionChange = extension => {
        if (!this.selectedDevice) {
          DeviceManager.logger.warn(
            'extension.onExtensionChange',
            'Cannot update extension state, there is no selected device.',
          );
          return;
        }
        DeviceManager.logger.info('extension.onExtensionChange', 'Extension has been changed', extension);
        exerciseActionTracker.add('alerts', 'extension-changed', {
          extension: { type: extension ? (extensionMap[extension.id] as ExtensionType) : null },
        });
        this.selectedDevice.extensionType = extension ? (extensionMap[extension.id] as ExtensionType) : undefined;
        this.notifyDeviceUpdate();
      };

      device.extension.onExtensionAttach = extension => {
        if (!this.selectedDevice) {
          DeviceManager.logger.warn(
            'extension.onExtensionAttach',
            'Cannot update extension state, there is no selected device.',
          );
          return;
        }
        DeviceManager.logger.info('extension.onExtensionAttach', 'Extension attached', extension);
        exerciseActionTracker.add('alerts', 'extension-attached', {
          extension: { type: extension ? (extensionMap[extension.id] as ExtensionType) : null },
        });
        this.selectedDevice.extensionType = extension ? (extensionMap[extension.id] as ExtensionType) : undefined;
        this.notifyDeviceUpdate();
      };

      device.extension.onExtensionDetach = () => {
        if (!this.selectedDevice) {
          DeviceManager.logger.warn(
            'cable.onExtensionDetach',
            'Cannot update cable state, there is no selected device.',
          );
          return;
        }

        DeviceManager.logger.info('cable.onExtensionDetach', 'Extension detached');
        exerciseActionTracker.add('alerts', 'extension-detached', {
          extension: { type: null },
        });
        this.selectedDevice.extensionType = null;

        this.notifyDeviceUpdate();
      };
    } else if (device.type === 'sidra-leg') {
      // This is fix for unimplemented sidra-leg extension types in firmware. To be removed with exo-session mechanizm when ready
      device.extensionType = 'flexion';
    }

    device.emergencyButton = device.session.activate(ExoEmergencyButtonFeature);
    device.emergencyButton.onUnlock = () => {
      this.notifyDeviceUpdate();
    };

    DeviceManager.logger.info('startSession', `EmergengyButtonFeature for device ${id} is activated`);

    device.cable.onChannelQualityChange = (idx, connectionQuality) => {
      if (!this.selectedDevice) {
        DeviceManager.logger.warn(
          'cable.onChannelQualityChange',
          'Cannot update cable state, there is no selected device.',
        );
        return;
      }

      exerciseActionTracker.add('alerts', 'channel-quality-change', { channels: { [idx]: connectionQuality } });

      DeviceManager.logger.debug('cable.onChannelQualityChange', 'Quality changed for channel', {
        idx,
        connectionQuality,
      });

      if (!this.selectedDevice.cableType?.channels.includes(idx)) {
        return;
      }

      this.selectedDevice.channelsConnectionQuality[idx] = connectionQuality;
      this.notifyDeviceUpdate();
    };

    device.cable.onCableChange = cableType => {
      if (!this.selectedDevice) {
        DeviceManager.logger.warn('cable.onCableChange', 'Cannot update cable state, there is no selected device.');
        return;
      }

      exerciseActionTracker.add('alerts', 'cable-changed', {
        cable: { id: cableType.id, description: cableType.description },
      });

      DeviceManager.logger.debug('cable.onCableChange', 'onCableChange', cableType);
      this.selectedDevice.cableType = cableType;
      this.notifyDeviceUpdate();
    };

    device.cable.onCableDetach = cableType => {
      if (!this.selectedDevice) {
        DeviceManager.logger.warn('cable.onCableDetach', 'Cannot update cable state, there is no selected device.');
        return;
      }

      exerciseActionTracker.add('alerts', 'cable-detached', {
        cable: { id: cableType.id, description: cableType.description },
      });

      DeviceManager.logger.debug('cable.onCableDetach', 'onCableDetach', cableType);
      this.selectedDevice.cableType = cableType;
      this.selectedDevice.cableStatus = 'detached';
      this.notifyDeviceUpdate();
    };

    device.cable.onCableAttach = cableType => {
      if (!this.selectedDevice) {
        DeviceManager.logger.warn('cable.onCableAttach', 'Cannot update cable state, there is no selected device.');
        return;
      }
      const maxSupportedChannels = this.session!.options?.cable!.maxSupportedChannels;
      if (!maxSupportedChannels) {
        throw new Error('maxSupportedChannels not exists');
      }

      exerciseActionTracker.add('alerts', 'cable-attached', {
        cable: { id: cableType.id, description: cableType.description },
      });

      DeviceManager.logger.debug('cable.onCableAttach', 'onCableAttach', cableType);
      this.selectedDevice.cableType = cableType;
      this.selectedDevice.cableStatus = 'attached';
      // We filter selectedDevice channels which for stella we get [0,1,2,3,4,5,6,7] by cable available channels.
      this.selectedDevice.channelsConnectionQuality = Object.fromEntries(
        cableType.channels
          .filter(ch => ch + 1 <= maxSupportedChannels)
          .map(v => [v, device.cable?.getChannelQuality(v) ?? ChannelConnectionQuality.NONE]),
      );
      this.notifyDeviceUpdate();
    };

    device.otherFeatures = [];

    if (device.session.options.motor) {
      for (const motorName in device.session.options.motor) {
        const motor = device.session.activate(ExoMotorFeature, { name: motorName, readonly: true });

        device.otherFeatures.push(motor);
        motor.onRemoteSpeedChange = speed => {
          notification(
            __('device.changedRemoteSpeed', { speed, motor: __('device.changedRemoteSpeed.motor.' + motorName) }),
            'info',
          );
        };
      }
    }

    this.devices[id] = device;
    await device.session.start();

    device.connectionStatus = 'connected';
    this.notifyDeviceUpdate();

    DeviceManager.logger.info('startSession', `Session for device ${id} is created and started`);
  }

  private async disposeSelectedDevice() {
    if (!this.selectedDevice) {
      throw new Error('Cannot dispose selected device, because no device is selected');
    }

    if (this.isDeviceDummy(this.selectedDevice.id)) {
      this.destroyDummyController();
    }

    if (this.selectedDevice.session) {
      // Session stop disposes all features for the device.
      await this.selectedDevice.session.stop();
      this.selectedDevice.session = null;
    }

    delete this.devices[this.selectedDevice.id];
    this._deviceState.selectedDeviceId = null;
  }

  private notifyDeviceUpdate() {
    if (!this.selectedDevice) {
      throw new Error('Cannot notify without selected device');
    }

    // check for local storage update
    if (this.isDeviceDummy(this.selectedDevice.id)) {
      this.saveDeviceDataToLocalStorage(this.selectedDevice);
    }

    EventBus.raise(this.events, 'onDeviceChange', this.selectedDevice);
  }

  private saveDeviceDataToLocalStorage(device: Device) {
    let localStorageData = localStorage.getItem('devices');
    if (!localStorageData) {
      localStorageData = JSON.stringify({});
    }

    const devices: Record<string, LocalStorageDevice> = JSON.parse(localStorageData);
    if (!devices[device.id]) {
      devices[device.id] = {
        id: device.id,
        displayName: device.displayName,
        type: device.type,
        lastTimeUsed: device.lastTimeUsed,
      };
    } else {
      devices[device.id].lastTimeUsed = device.lastTimeUsed;
    }

    if (this.isDeviceDummy(device.id) && device.updatedDummyFromLocalStorage) {
      devices[device.id].channelsConnectionQuality = device.channelsConnectionQuality;
      devices[device.id].cableType = device.cableType;
      devices[device.id].extensionType = device.extensionType;
    }

    this._deviceState.storedCandidates = devices;
    localStorage.setItem('devices', JSON.stringify(devices));
    EventBus.raise(this.events, 'onStoredCandidatesChange', devices);
  }

  private getDeviceDataFromLocalStorage(id: Device['id']): LocalStorageDevice | null {
    const localStorageData = localStorage.getItem('devices');
    if (localStorageData) {
      const devices: Record<string, LocalStorageDevice> = JSON.parse(localStorageData);

      this._deviceState.storedCandidates = devices;
      EventBus.raise(this.events, 'onStoredCandidatesChange', devices);

      if (devices[id]) {
        return devices[id];
      }
    }
    return null;
  }

  private updateDummyControllerFromLocalStorage(id: Device['id']) {
    if (this.isDeviceDummy(id)) {
      const deviceData = this.getDeviceDataFromLocalStorage(id);
      const maxSupportedChannels = this.session!.options?.cable!.maxSupportedChannels;
      if (!maxSupportedChannels) {
        throw new Error('maxSupportedChannels not exists');
      }

      // FIXME: There is no way (?) to start dummy devices
      // with predefined configuration, so we have to
      // wait for ExoCable initialization
      setTimeout(() => {
        if (this._dummyController) {
          if (deviceData?.channelsConnectionQuality) {
            for (const channelNumber in deviceData.channelsConnectionQuality) {
              if (parseInt(channelNumber) + 1 <= maxSupportedChannels) {
                this._dummyController.cable.setChannelQuality(
                  Number(channelNumber),
                  deviceData.channelsConnectionQuality[channelNumber],
                );
              }
            }
          }
          if (deviceData?.cableType) {
            this._dummyController.cable.setCable(deviceData.cableType);
          }
          if (this.selectedDevice?.type === 'meissa-ot') {
            const extension = deviceData?.extensionType
              ? this.session?.options.extension?.types[deviceData?.extensionType]
              : undefined;
            (this._dummyController as MeissaOTDummyController).extension.setExtension(extension ?? null);
          }
        }

        if (this.selectedDevice) {
          this.selectedDevice.updatedDummyFromLocalStorage = true;
          this.notifyDeviceUpdate();
        }
      }, 100);
    }
  }
}
