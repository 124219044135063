import { useEffect } from 'react';
import { useTrainingList } from 'hooks/lists/useTrainingList';
import { useAppSelector } from 'hooks/store';

import { TrainingFilter } from '../_containers/TrainingFilter';
import { TrainingList } from '../_containers/TrainingList';

const FavoriteTraining = () => {
  const { loadTrainings, setFavoriteCategory } = useTrainingList();
  const { isAvailableDevicesTypesLoaded } = useAppSelector(state => state.trainingList);
  useEffect(() => {
    if (isAvailableDevicesTypesLoaded) {
      loadTrainings({ isFavoriteTraining: true });
      setFavoriteCategory(true);
    }
    // Get the list only once, but in case we have already got information about available devices from the backend
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailableDevicesTypesLoaded]);

  return (
    <>
      <TrainingFilter />
      <TrainingList favorite />
    </>
  );
};

export default FavoriteTraining;
