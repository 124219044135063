import { useContext } from 'react';
import { SettingsBuilder } from 'libs/exo-session-manager/core/settings/SettingsBuilder';

import { CAMInstanceContext } from '../providers/CAMInstanceContext';
import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';
import { EMSInstanceContext } from '../providers/EMSInstanceContext';

export const useSettings = (settings?: SettingsBuilder): SettingsBuilder => {
  const cpm = useContext(CPMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);
  const cam = useContext(CAMInstanceContext);
  const ems = useContext(EMSInstanceContext);

  if (settings) {
    return settings;
  }

  if (cpm) {
    return cpm.settings;
  }

  if (cpmEms) {
    return cpmEms.settings;
  }

  if (cam) {
    return cam.settings;
  }

  if (ems) {
    return ems.settings;
  }
  throw new Error('useSettings must be used within a Provider which requires CPM feature');
};
