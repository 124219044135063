import { ExoSessionError } from '@egzotech/exo-session/dist/src/ExoSessionError';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { ElectrostimProgram } from '@egzotech/exo-session/features/electrostim';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiFetch } from 'config/api';
import { State } from 'config/store';
import { handleError } from 'helpers/handleError';
import { GameProtocolMessage } from 'libs/exo-game-service';
import {
  CalibrationFlowStatesTypedData,
  CAMExerciseDefinition,
  CPMExerciseDefinition,
  DeepWritable,
  EMGProgram,
  EMSExerciseDefinition,
  ExerciseDefinition,
  ExerciseType,
  ExtensionType,
  FinishedReason,
  GeneratedCAMProgramDefinitionPrimary,
  GeneratedCPMProgramDefinitionPrimary,
  MotorPlacement,
  SensorsName,
} from 'libs/exo-session-manager/core';
import { BodyPartMuscle, Exercise, TrainingResponseDTO } from 'types';

export type DeepWriteable<T> = {
  -readonly [key in keyof T]: DeepWriteable<T[key]>;
};

export interface TrainingReportState {
  /**
   * Contains basic information about a training, should be loaded using {@link loadTraining} thunk.
   */
  training: TrainingResponseDTO | null;
  /**
   * Contains information saved from exercises during a training
   */
  trainingData: TrainingData | null;
  /**
   * Contains signal data for each exercise in the training, should be loaded using {@link loadTrainingSignals} thunk,
   * after loading an exercise.
   */
  trainingSignals: Array<{
    motion?: Record<
      string,
      {
        samples: Float32Array;
        timePoints: Uint32Array;
      }
    >;
    emgChannels?: Record<
      string,
      {
        samples: Float32Array;
        timePoints: Uint32Array;
        guideLine: Float32Array;
      }
    >;
  }> | null;
  /**
   * Indicates that basic information is being currently loaded.
   */
  isLoading: boolean;
  /**
   * Indicates that loading basic information has failed.
   */
  hasLoadingFailed: boolean;
  /**
   * Indicates that signal data is being currently loaded.
   */
  isLoadingSignals: boolean;
  /**
   * Indicates that loading signal data has failed.
   */
  hasLoadingSignalsFailed: boolean;
  /**
   * Returns the progress of loading signal data in the range of [0, 1].
   */
  loadingSignalsProgress: number;
}

export interface ExerciseTimelineEntryBase {
  /**
   * Moment at which the event happened, unit is seconds
   */
  time: number;
  /**
   * Repetition at which the event happened, if exercise does not have repetitions
   * then this property is not set
   */
  repetition?: number;
  /**
   * Type of the event
   */
  type: string;

  /**
   * Description of the event
   */
  description: `trainingReport.exerciseTimeline.events.${string}`;

  /**
   * Phase of the timeline
   */
  phase?: 'calibration' | 'before-exercise' | 'exercise';
}

export interface ExerciseTimelineEntryEmsChangeCurrent extends ExerciseTimelineEntryBase {
  type: 'ems-change-current';
  /**
   * Channel for which the current was changed
   */
  channel: number;
  /**
   * Old value of current for this channel, unit is mA
   */
  from: number;
  /**
   * New value of current for this channel, unit is mA
   */
  to: number;
}

export interface ExerciseTimelineEntryEmsEmgTriggeredChangeThreshold extends ExerciseTimelineEntryBase {
  type: 'ems-emg-tirggered-change-threshold';
  /**
   * Channel for which the threshold was changed
   */
  channel: number;
  to: number;
}

export interface ExerciseTimelineEntryEmgChangeCalibration extends ExerciseTimelineEntryBase {
  type: 'emg-change-calibration';
  /**
   * Map of channels for which the calibration was changed, key is the channel number
   */
  channels: Record<
    string,
    {
      /**
       * Contains MVC changes for this channel
       */
      mvc: {
        /**
         * Old value of MVC for this channel, unit is uV
         */
        from: number;
        /**
         * New value of MVC for this channel, unit is uV
         */
        to: number;
      };
    }
  >;
}

export interface ExerciseTimelineEntryPlay extends ExerciseTimelineEntryBase {
  type: 'play';
}

export interface ExerciseTimelineEntryPause extends ExerciseTimelineEntryBase {
  type: 'pause';
  /**
   * Duration of the pause, unit is seconds
   */
  duration?: number;
}

export interface ExerciseTimelineEntryStop extends ExerciseTimelineEntryBase {
  type: 'stop';
  /**
   * Specifies the reason why exercise was stopped
   */
  reason: FinishedReason;
}

export interface ExerciseTimelineEntryCableAlert extends ExerciseTimelineEntryBase {
  type: 'cable-changed' | 'cable-detached' | 'cable-attached' | 'spasticism-active' | 'spasticism-inactive';
  cableId?: number;
  cableDescription?: string;
  error?: ExoSessionError;
}

export interface ExerciseTimelineEntryExtensionAlert extends ExerciseTimelineEntryBase {
  type: 'extension-changed' | 'extension-detached' | 'extension-attached';
  extensionType: ExtensionType;
}

export interface ExerciseTimelineEntryChannelQualityAlert extends ExerciseTimelineEntryBase {
  type: 'channel-quality-change';
  channels: Record<number, ChannelConnectionQuality>;
}

export interface ExerciseTimelineEntrySessionErrorAlert extends ExerciseTimelineEntryBase {
  type: 'session-error';
  error?: ExoSessionError;
}

export interface ExerciseTimelineEntryParameterChange {
  /**
   * Old value of threshold for this channel, unit is uV
   */
  from: number | string;
  /**
   * New value of threshold for this channel, unit is uV
   */
  to: number | string;

  /**
   * Description of the parameter that changed
   */
  paramDescription: `training.settings.parameters.${string}`;

  unit?: string;
}

export interface ExerciseTimelineEntryForceThresholdChange {
  from: number | string;
  to: number | string;
  paramDescription: string;
  unit?: string;
  sensorName?: SensorsName;
  isNegative: boolean;
}

export interface ExerciseTimelineEntryForceChange
  extends ExerciseTimelineEntryBase,
    ExerciseTimelineEntryForceThresholdChange {
  type: 'increase-force-threshold' | 'decrease-force-threshold';
}

export interface ExerciseTimelineEntryCPMParameterChange
  extends ExerciseTimelineEntryBase,
    ExerciseTimelineEntryParameterChange {
  type: 'cpm-parameter-change';
}

export interface ExerciseTimelineEntryCAMParameterChange
  extends ExerciseTimelineEntryBase,
    ExerciseTimelineEntryParameterChange {
  type: 'cam-parameter-change';
}

export interface ExerciseTimelineEntryGameParameterChange
  extends ExerciseTimelineEntryBase,
    ExerciseTimelineEntryParameterChange {
  type: 'game-parameter-change';
}

export function isExerciseTimelineEntryCPMParameterChange(obj: object): obj is ExerciseTimelineEntryCPMParameterChange {
  return 'type' in obj && typeof obj.type === 'string' && obj.type === 'cpm-parameter-change';
}

export function isExerciseTimelineEntryCAMParameterChange(obj: object): obj is ExerciseTimelineEntryCPMParameterChange {
  return 'type' in obj && typeof obj.type === 'string' && obj.type === 'cam-parameter-change';
}

export function isExerciseTimelineEntryGameParameterChange(
  obj: object,
): obj is ExerciseTimelineEntryCPMParameterChange {
  return 'type' in obj && typeof obj.type === 'string' && obj.type === 'game-parameter-change';
}

export interface ExerciseTimelineEntryEmsParameterChange
  extends ExerciseTimelineEntryBase,
    ExerciseTimelineEntryParameterChange {
  type: 'ems-parameter-change';
  /**
   * Channel for which the parameter was changed
   */
  channel: number;
}

export interface ExerciseTimelineEntryEmgParameterChange
  extends ExerciseTimelineEntryBase,
    ExerciseTimelineEntryParameterChange {
  type: 'emg-parameter-change';
  /**
   * Channel for which the parameter was changed
   */
  channel: number;
}

export function isExerciseTimelineEntryEmgParameterChange(obj: object): obj is ExerciseTimelineEntryEmgParameterChange {
  return (
    'type' in obj &&
    typeof obj.type === 'string' &&
    obj.type === 'emg-parameter-change' &&
    'channel' in obj &&
    typeof obj.channel === 'number'
  );
}

export function isExerciseTimelineEntryEmsParameterChange(obj: object): obj is ExerciseTimelineEntryEmsParameterChange {
  return (
    'type' in obj &&
    typeof obj.type === 'string' &&
    obj.type === 'ems-parameter-change' &&
    'channel' in obj &&
    typeof obj.channel === 'number'
  );
}

export type ExerciseTimelineEntry =
  | ExerciseTimelineEntryEmsChangeCurrent
  | ExerciseTimelineEntryEmsEmgTriggeredChangeThreshold
  | ExerciseTimelineEntryEmgChangeCalibration
  | ExerciseTimelineEntryPause
  | ExerciseTimelineEntryStop
  | ExerciseTimelineEntryPlay
  | ExerciseTimelineEntryForceChange
  | ExerciseTimelineEntryCPMParameterChange
  | ExerciseTimelineEntryEmsParameterChange
  | ExerciseTimelineEntryEmgParameterChange
  | ExerciseTimelineEntryGameParameterChange
  | ExerciseTimelineEntryCAMParameterChange
  | ExerciseTimelineEntryCableAlert
  | ExerciseTimelineEntryExtensionAlert
  | ExerciseTimelineEntryChannelQualityAlert
  | ExerciseTimelineEntrySessionErrorAlert;

export interface ExerciseDataBase {
  /**
   * Id of the exercise in library
   */
  id: string;
  /**
   * Specifies in which library the exercise is located
   */
  type: 'emg' | 'emg-pelvic' | 'ems';
  /**
   * Specifies a time at which the exercise was started,
   */
  startTime: string;
  /**
   * Specifies a time at which the exercise was ended,
   */
  endTime: string;
  /**
   * Specifies how many repetitions user made in this exercise
   */
  repetitions: number;
  /**
   * Specifies real duration of the exercise (including pauses), unit is seconds
   */
  duration: number;
  /**
   * Additional parameters for this exercise that changed program behavior from the library
   */
  parameters: Record<string, string>;
  /**
   * Timeline containing entries describing changes in basic parameters of the channels and
   * events that happened during the exercise
   */
  timeline: ExerciseTimelineEntry[];
  /**
   * Current version of uploaded data
   */
  version?: string;
}

export interface ExerciseDataEms extends ExerciseDataBase {
  type: 'ems';
  /**
   * Program definition that was used for this exercise
   */
  program: ElectrostimProgram;
  /**
   * Parameters for each channel in this exercise, the key of this map is a channel number
   */
  channels: Record<
    string,
    {
      /**
       * Unique identifier of the muscle that is assigned to a channel
       */
      muscle: string;
      /**
       * Current that was set at the beggining of the exercise for this channel, if channel is used
       * only for EMG then this value should be zero, unit is mA
       */
      current: number;
      /**
       * MVC that was set at the first calibration of the exercise for this channel, if channel is used
       * only for EMS then this value should be zero, unit is uV
       */
      mvc: number;
      /**
       * Threshold that was set at the beggining of the exercise for this channel, if this channel is
       * used only for EMS then this value should be zero, unit is uV
       */
      threshold: number;
      /**
       * Purpose of this channel:
       * - emg - channel used for measuring EMG
       * - ems - channel used for electrostimulation
       * - ems+emg - channel used for electrostimulation that is triggered with EMG
       */
      purpose: 'ems' | 'emg' | 'ems+emg';
      /**
       * Holds URL's for the channel signals data
       */
      signal: {
        /**
         * URL for channel RMS data
         */
        samples: string;
        /**
         * URL for channel time points data
         */
        timePoints?: string;
        /**
         * URL for channel guide line data
         */
        guideLine: string;
      };
    }
  >;
  /**
   * URL for time points data for all channels
   */
  timePoints?: string;
}

export interface ExerciseDataEmg extends ExerciseDataBase {
  type: 'emg';
  /**
   * Program definition that was used for this exercise
   */
  program: EMGProgram;
  /**
   * Parameters for each channel in this exercise, the key of this map is a channel number
   */
  channels: Record<
    string,
    {
      /**
       * Unique identifier of the muscle that is assigned to a channel
       */
      muscle: string;
      /**
       * MVC that was set at the first calibration of the exercise for this channel, if channel is used
       * only for EMS then this value should be zero, unit is uV
       */
      mvc: number;
      /**
       * Threshold that was set at the beggining of the exercise for this channel, if this channel is
       * used only for EMS then this value should be zero, unit is uV
       */
      threshold: number;
      /**
       * Holds URL's for the channel signals data
       */
      signal: {
        /**
         * URL for channel RMS data
         */
        samples: string;
        /**
         * URL for channel guide line data
         */
        guideLine: string;
        /**
         * URL for channel time points data
         */
        timePoints?: string;
      };
    }
  >;
  /**
   * URL for time points data for all channels
   */
  timePoints?: string;
}

export interface ExerciseMotionData {
  repetitions: number;
  recordings?: Record<
    string,
    {
      /**
       * URL for samples data
       */
      samples: string;
      /**
       * URL for timePoints data
       */
      timePoints?: string;
    }
  >;
  /**
   * URL for timePoints data for synchronized samples
   */
  timePoints?: string;
}

export type ExerciseData = ExerciseDataEms | ExerciseDataEmg;

export type ExerciseReportDataBase = {
  /**
   * Id of the exercise in library
   */
  id: string;
  /**
   * Type of the exercise in library
   */
  type: ExerciseType;
  /**
   * Definition of the exercise
   */
  definition: ExerciseDefinition;
  /**
   * Data from calibration
   */
  calibration: CalibrationFlowStatesTypedData;
  /**
   * Specifies a time at which the exercise was started,
   */
  startTime: string;
  /**
   * Specifies a time at which the exercise was ended,
   */
  endTime: string;
  /**
   * Specifies real duration of the exercise (including pauses), unit is seconds
   */
  duration: number;
  /**
   * Timeline containing entries describing changes in basic parameters of the channels and
   * events that happened during the exercise
   */
  timeline: ExerciseTimelineEntry[];
  /**
   * Parameters for each channel in this exercise, the key of this map is a channel number
   */
  channels: Record<
    string,
    {
      /**
       * Unique identifier of the muscle that is assigned to a channel
       */
      muscle: BodyPartMuscle;
      /**
       * MVC that was set at the first calibration of the exercise for this channel, if channel is used
       * only for EMS then this value should be zero, unit is uV
       */
      mvc: number;
      /**
       * Threshold that was set at the beggining of the exercise for this channel, if this channel is
       * used only for EMS then this value should be zero, unit is uV
       */
      threshold: number;
      /**
       * Holds URL's for the channel signals data
       */
      signal?: {
        /**
         * URL for channel RMS data
         */
        samples: string;
        /**
         * URL for time points data data
         */
        timePoints?: string;
        /**
         * URL for channel guide line data
         */
        guideLine: string;
      };
    }
  >;
  /**
   * URL for time points data for all channels
   */
  timePoints?: string;
  /**
   * Points that user has gained during game for each level
   */
  gameResults?: GameProtocolMessage<'result'>['value'];

  /**
   * Current version of uploaded data
   */
  version?: string;
};

export interface EMSExerciseReportData extends ExerciseReportDataBase {
  type: 'ems' | 'ems-emg';
  definition: EMSExerciseDefinition;
  motion: ExerciseMotionData; // TODO: to be deleted
  triggersTimestamps: number[];
}

export interface CAMExerciseReportData extends ExerciseReportDataBase {
  type: 'cam-isokinetic' | 'cam-torque' | 'cam-turn-key' | 'cam-game' | 'cam-game-position' | 'cam-game-force';
  definition: CAMExerciseDefinition;
  primaryProgramDefinition: GeneratedCAMProgramDefinitionPrimary;
  motion: ExerciseMotionData;
  synchronized: boolean;
  primaryMotor: MotorPlacement | null;
  secondaryMotor: MotorPlacement | null;
  triggersTimestamps: number[];
  // TODO: Make CAMExerciseReportData versions
  /** @depreciated - Replaced by triggersTimestamps */
  emgTriggersTimestamps?: number[];
}

export interface CPMExerciseReportData extends ExerciseReportDataBase {
  type: 'cpm' | 'cpm-emg' | 'cpm-ems' | 'cpm-ems-emg' | 'cpm-force';
  definition: CPMExerciseDefinition;
  primaryProgramDefinition: GeneratedCPMProgramDefinitionPrimary;
  motion: ExerciseMotionData;
  synchronized: boolean;
  primaryMotor: MotorPlacement | null;
  secondaryMotor: MotorPlacement | null;
  triggersTimestamps: number[];
  // TODO: Make CPMExerciseReportData versions
  /** @depreciated - Replaced by triggersTimestamps */
  emgTriggersTimestamps?: number[];
}

export type ExerciseReportData = CAMExerciseReportData | CPMExerciseReportData | EMSExerciseReportData | ExerciseData;

export interface TrainingData {
  /**
   * Data of performed exercises, if length of the exercises does not match length from training
   * template then some of the exercises were not carried out
   */
  exercises: ExerciseReportData[];
}

export interface TrainingDataDTO {
  /**
   * Data of performed exercise
   */
  exercise: ExerciseReportData;
}

export type TrainingTemplateExercises = Record<string, Partial<Exercise>>;

type ValueOf<T> = T[keyof T];

export type TrainingTemplateExercise = ValueOf<TrainingTemplateExercises>;

type DecodedSignal = {
  type: 'plain' | 'gz' | 'bin';
  data: Float32Array | Uint32Array;
};

async function downloadAndDecodeSignal(trainingId: string, entryId: string): Promise<DecodedSignal> {
  const apiUrl = `/training/` + trainingId + `/entry/` + entryId;

  if (entryId.endsWith('_gz.dat')) {
    await (await apiFetch(apiUrl)).arrayBuffer();
    throw new Error('GZIP data decompression not implemened');
  }

  if (entryId.endsWith('_bin.dat')) {
    const data = await (await apiFetch(apiUrl)).arrayBuffer();
    const binaryValues = new Uint8Array(data);
    const values = apiUrl.includes('time_points')
      ? new Uint32Array(binaryValues.buffer, binaryValues.byteOffset, binaryValues.byteLength / 4)
      : new Float32Array(binaryValues.buffer, binaryValues.byteOffset, binaryValues.byteLength / 4);

    return {
      type: 'bin',
      data: values,
    };
  }

  return {
    type: 'plain',
    data: new Float32Array((await (await apiFetch(apiUrl)).text()).split(',').map(Number)),
  };
}

const initialState: TrainingReportState = {
  training: null,
  trainingData: null,
  trainingSignals: null,
  hasLoadingFailed: false,
  isLoading: false,
  hasLoadingSignalsFailed: false,
  isLoadingSignals: false,
  loadingSignalsProgress: 0,
};

export const loadTraining = createAsyncThunk('trainingReport/load', async (arg: { id: string }) => {
  const training = await apiFetch<TrainingResponseDTO>(`/training/${arg.id}`)
    .then(async res => await res.json())
    .catch(err => {
      handleError(err.response);
      throw err;
    });

  return training;
});

export const loadTrainingSignals = createAsyncThunk(
  'trainingReport/loadSignals',
  async (arg: { id: string }, thunkApi) => {
    const { trainingReport } = thunkApi.getState() as State;

    if (!trainingReport) {
      throw new Error('Cannot load data without loaded training.');
    }

    if (!trainingReport.trainingData || !trainingReport.trainingData.exercises.length) {
      throw new Error('Training does not have any training data uploaded.');
    }

    const result: TrainingReportState['trainingSignals'] = [];
    const promises = [];
    let progress = 0;

    for (let i = 0; i < trainingReport.trainingData.exercises.length; i++) {
      const exercise = trainingReport.trainingData.exercises[i];
      const exerciseData: (typeof result)[number] = {
        emgChannels: {},
        motion: {},
      };

      result.push(exerciseData);

      if (Object.values(exercise?.channels).some(v => !!v.signal)) {
        for (const prop in exercise.channels) {
          const channel = exercise.channels[prop];
          if (exerciseData.emgChannels) {
            exerciseData.emgChannels[prop] = { samples: null!, guideLine: null!, timePoints: null! };
          }
          if (channel?.signal?.samples) {
            promises.push(
              downloadAndDecodeSignal(arg.id, channel.signal.samples).then(v => {
                if (exerciseData.emgChannels) {
                  exerciseData.emgChannels[prop].samples = v.data as Float32Array;
                }
                progress++;
                thunkApi.dispatch(trainingListSlice.actions.setLoadingDataProgress(progress / promises.length));
              }),
            );
            if (channel.signal.guideLine) {
              promises.push(
                downloadAndDecodeSignal(arg.id, channel.signal.guideLine).then(v => {
                  if (exerciseData.emgChannels) {
                    exerciseData.emgChannels[prop].guideLine = v.data as Float32Array;
                  }
                  progress++;
                  thunkApi.dispatch(trainingListSlice.actions.setLoadingDataProgress(progress / promises.length));
                }),
              );
            }
          }
          if (channel?.signal?.samples && exercise.timePoints) {
            promises.push(
              downloadAndDecodeSignal(arg.id, exercise.timePoints).then(v => {
                if (exerciseData.emgChannels) {
                  exerciseData.emgChannels[prop].timePoints = v.data as Uint32Array;
                }
                progress++;
                thunkApi.dispatch(trainingListSlice.actions.setLoadingDataProgress(progress / promises.length));
              }),
            );
          }
        }
      }
      if ('motion' in exercise && exercise.motion?.recordings) {
        Object.entries(exercise.motion.recordings).forEach(([k, v]) => {
          if (exerciseData.motion) {
            exerciseData.motion[k] = { samples: null!, timePoints: null! };
          }
          promises.push(
            downloadAndDecodeSignal(arg.id, v.samples).then(signal => {
              if (exerciseData.motion) {
                exerciseData.motion[k].samples = signal.data as Float32Array;
              }
              progress++;
              thunkApi.dispatch(trainingListSlice.actions.setLoadingDataProgress(progress / promises.length));
            }),
          );
        });
        if (exercise.motion?.timePoints) {
          promises.push(
            downloadAndDecodeSignal(arg.id, exercise.motion.timePoints).then(signal => {
              if (exerciseData.motion) {
                Object.keys(exerciseData.motion).forEach(k => {
                  if (exerciseData.motion?.[k]) {
                    exerciseData.motion[k].timePoints = signal.data as Uint32Array;
                  }
                });
              }
              progress++;
              thunkApi.dispatch(trainingListSlice.actions.setLoadingDataProgress(progress / promises.length));
            }),
          );
        }
      }

      await Promise.all(promises);

      return result;
    }
  },
);

export const trainingListSlice = createSlice({
  name: 'trainingList',
  initialState,
  reducers: {
    setLoadingDataProgress: (state, { payload }: PayloadAction<number>) => {
      state.loadingSignalsProgress = payload;
    },
    clearTraining: state => {
      state.training = null;
      state.trainingData = null;
      state.trainingSignals = null;
      state.isLoading = false;
      state.hasLoadingFailed = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTraining.pending, state => {
      state.training = null;
      state.trainingData = null;
      state.trainingSignals = null;
      state.isLoading = true;
      state.hasLoadingFailed = false;
    });

    builder.addCase(loadTraining.fulfilled, (state, { payload }) => {
      state.training = payload;
      state.isLoading = false;
      state.hasLoadingFailed = false;

      const data: TrainingData = {
        exercises: [],
      };

      for (let i = 0; i < payload.trainingData.length; i++) {
        data.exercises.push((payload.trainingData[i].data as TrainingDataDTO).exercise);
      }

      state.trainingData = data as DeepWritable<TrainingData>;
    });

    builder.addCase(loadTraining.rejected, state => {
      state.training = null;
      state.isLoading = false;
      state.hasLoadingFailed = true;
    });

    builder.addCase(loadTrainingSignals.pending, state => {
      state.loadingSignalsProgress = 0;
      state.trainingSignals = null;
      state.isLoadingSignals = true;
      state.hasLoadingSignalsFailed = false;
    });

    builder.addCase(loadTrainingSignals.fulfilled, (state, { payload }) => {
      state.loadingSignalsProgress = 1;
      state.trainingSignals = payload ?? [];
      state.isLoadingSignals = false;
      state.hasLoadingSignalsFailed = false;
    });

    builder.addCase(loadTrainingSignals.rejected, state => {
      state.loadingSignalsProgress = 0;
      state.trainingSignals = null;
      state.isLoadingSignals = false;
      state.hasLoadingSignalsFailed = true;
    });
  },
});

export default trainingListSlice.reducer;
