import { DeepReadonly, ExoSession } from '@egzotech/exo-session';
import { ExoCAMFeature } from '@egzotech/exo-session/features/cam';
import { Recordable } from '@egzotech/exo-session/features/common';
import { ExoMotorFeature } from '@egzotech/exo-session/features/motor';
import { Logger } from '@egzotech/universal-logger-js';
import { Timers } from 'config/timers';
import { getMotorNameByProgram } from 'helpers/getMotorNameByProgram';
import { Signal, signal } from 'helpers/signal';
import { calculateSensitivity } from 'helpers/units';
import { ForceCalibrationData } from 'views/+patientId/training/+trainingId/_components/force-calibration/ForceCalibration';

import { CalibrationFlowStatesTypedData } from '../common/CalibrationFlow';
import { RemotePlugin } from '../common/RemotePlugin';
import { Trigger } from '../common/Trigger';
import { TriggerThresholdForce } from '../common/TriggerThresholdForce';
import { DeviceType, ExtensionType } from '../global/DeviceManager';
import { initSensorData, SensorForcePlugin } from '../global/SensorForcePlugin';
import { SettingsBuilder } from '../settings/SettingsBuilder';
import { MotorRange, SensorChange, SensorsName } from '../types';
import { CAMBasingData } from '../types/CAMBasingData';
import { CAMProgramData } from '../types/CAMProgramData';
import {
  CAMProgramDefinitionSynchronized,
  GeneratedCAMProgramDefinitionPrimary,
  isGeneratedCAMProgramDefinitionPassive,
} from '../types/GeneratedCAMProgramDefinition';
import {
  CAMExerciseDefinition,
  GeneratedCAMLikeExerciseDefinition,
  GeneratedExerciseDefinition,
} from '../types/GeneratedExerciseDefinition';
import { MotorPlacement } from '../types/GeneratedProgramDefinition';
import { exerciseActionTracker } from '..';

import { Recordings, SignalRecorderController } from './../common/SignalRecorderController';
import { Exercise, ExerciseFeature } from './Exercise';
import { FinishedReason } from './FinishedReason';

export class CAMExercise implements Exercise {
  private _camPrimaryFeature: ExoCAMFeature | null = null;
  private _camSynchronizedFeature: ExoMotorFeature | null = null;
  private _recorderController: SignalRecorderController | null = null;
  private _recordings: Recordings = {};
  private _passiveMotorFeatures: ExoMotorFeature[] = [];
  private _sensorForcePlugin: SensorForcePlugin | null = null;
  private _remotePlugin: RemotePlugin;

  private _primaryCAMMotor: MotorPlacement;
  private _synchronizedCAMMotor: MotorPlacement | null = null;
  // TODO make extension type universal for different devices
  private _extensionType: ExtensionType | null = null;
  private _programData: {
    cam: CAMProgramData;
  };
  private calibrationData: DeepReadonly<CalibrationFlowStatesTypedData> | null = null;

  private _exerciseData: {
    exerciseName: Signal<string | null>;
    totalRepetition: Signal<number>;
    totalDuration: Signal<number>;
  };

  private _exerciseDefinition: CAMExerciseDefinition;

  private _basingData: Signal<CAMBasingData> = signal({}, 'CAMExercise._basingData');

  private _settingsBuilder: SettingsBuilder;

  private _triggers: Trigger[] = [];

  private _currentPhaseIndex = 0;
  private _currentPhaseRepetitionKey = 0;

  static readonly logger = Logger.getInstance('CAMExercise');

  private _timeInterval: NodeJS.Timeout | null = null;

  private _holdStatus: 'held' | 'released' | null = null;

  readonly prepared = signal(false, 'CAMExercise.prepared');

  constructor(
    exerciseDefinition: GeneratedCAMLikeExerciseDefinition,
    private readonly session: ExoSession,
    private readonly exerciseName: string | null,
    private readonly deviceType: DeviceType,
  ) {
    this._programData = {
      cam: {
        initialized: signal(false, 'CAMExercise._programData.initialized'),
        active: signal(false, 'CAMExercise._programData.active'),
        running: signal(false, 'CAMExercise._programData.running'),
        finished: signal(false, 'CAMExercise._programData.finished'),
        finishedReason: signal('exerciseFinished', 'CAMExercise._programData.finishedReason'),
        currentRepetition: signal(0, 'CAMExercise._programData.currentRepetition'),
        currentDuration: signal(0, 'CAMExercise._programData.currentDuration'),

        primaryAngle: signal(null, 'CAMExercise._programData.primaryAngle'),
        primaryMotor: signal(null, 'CAMExercise._programData.primaryMotor'),

        synchronizedAngle: signal(null, 'CAMExercise._programData.synchronizedAngle'),
        synchronizedMotor: signal(null, 'CAMExercise._programData.synchronizedMotor'),

        sensorsData: signal(structuredClone(initSensorData), 'CAMExercise._programData.sensorsData'),

        currentDirection: signal(null, 'CAMExercise._programData.currentDuration'),
      },
    };

    this._exerciseData = {
      exerciseName: signal(this.exerciseName, 'CAMExercise._exerciseData.exerciseName'),
      totalDuration: signal(0, 'CAMExercise.totalDuration'),
      totalRepetition: signal(0, 'CAMExercise.totalRepetition'),
    };

    this._exerciseDefinition = structuredClone(exerciseDefinition) as CAMExerciseDefinition;

    const primaryKey = getMotorNameByProgram(this._exerciseDefinition, 'primary');

    if (!primaryKey || typeof primaryKey !== 'string') {
      throw new Error('Cannot find primary CAM program in exercise definition');
    }

    this._primaryCAMMotor = primaryKey;

    const synchronizedKey = getMotorNameByProgram(this._exerciseDefinition, 'synchronized');

    if (synchronizedKey) {
      this._synchronizedCAMMotor = synchronizedKey;
    }

    if (
      this.synchronizedProgramDefinition &&
      this.synchronizedProgramDefinition.synchronized !== this._primaryCAMMotor
    ) {
      throw new Error('Synchronized motor is different than the primary. This is not supported.');
    }

    exerciseActionTracker.timePoints.calibrationFlow.start = new Date();
    this._settingsBuilder = new SettingsBuilder(exerciseDefinition);
    this._remotePlugin = new RemotePlugin(this.session, this);
  }

  prepare(calibrationData: DeepReadonly<CalibrationFlowStatesTypedData>) {
    this.prepared.value = true;
    this.calibrationData = calibrationData;

    this._settingsBuilder = new SettingsBuilder(
      (calibrationData['basing-settings']?.definition as GeneratedExerciseDefinition | undefined) ??
        this._exerciseDefinition,
    );

    this._settingsBuilder.updateDefinition(this._exerciseDefinition);

    if (this.deviceType === 'sidra-leg') {
      const primaryMotor = this._primaryCAMMotor as 'knee' | 'ankle';
      const primaryMotorRange =
        this.calibrationData[`leg-basing-max-flexion-and-extension-measurement-passive-${primaryMotor}`];

      if (typeof primaryMotorRange?.min !== 'number' || typeof primaryMotorRange.max !== 'number') {
        throw new Error(`Missing passive range for primary motor '${primaryMotor}'`);
      }

      this.setMotorRange({ min: primaryMotorRange.min, max: primaryMotorRange.max }, primaryMotor);

      if (this._synchronizedCAMMotor) {
        const synchronizedMotor = this._synchronizedCAMMotor as 'knee' | 'ankle';
        const synchronizedMotorRange =
          this.calibrationData[`leg-basing-max-flexion-and-extension-measurement-passive-${synchronizedMotor}`];

        if (typeof synchronizedMotorRange?.min !== 'number' || typeof synchronizedMotorRange.max !== 'number') {
          throw new Error(`Missing passive range for synchronized motor '${synchronizedMotor}'`);
        }

        this.setMotorRange({ min: synchronizedMotorRange.min, max: synchronizedMotorRange.max }, synchronizedMotor);
      }
    } else if (this.deviceType === 'meissa-ot') {
      const primaryMotorRange = this.calibrationData[`meissa-basing-range-of-movement`];

      if (typeof primaryMotorRange?.min !== 'number' || typeof primaryMotorRange.max !== 'number') {
        throw new Error(`Missing range for primary motor 'main'`);
      }

      this.setMotorRange({ min: primaryMotorRange.min, max: primaryMotorRange.max }, 'main');
    }

    const recordables: Recordable<'single' | 'multi'>[] = [];

    if (this._camPrimaryFeature) {
      recordables.push(this._camPrimaryFeature.getRecordable());
    }

    if (this._camSynchronizedFeature) {
      recordables.push(this._camSynchronizedFeature);
    }

    if (Object.values(this.requiredForceSensors).length > 0) {
      this._sensorForcePlugin = new SensorForcePlugin(
        this.session,
        Object.values(this.requiredForceSensors) as SensorsName[],
      );
      this._sensorForcePlugin.onSensorData = this.handleSensorData.bind(this);
      this._sensorForcePlugin.onSensorAdditionalData = this.handleSensorAdditionalData.bind(this);
      recordables.push(...this._sensorForcePlugin.getRecordables());
    }

    if (recordables.length > 0) {
      this._recorderController = new SignalRecorderController(recordables);
    }

    const calibration = this.calibrationData?.['force-calibration'] as ForceCalibrationData | undefined;

    const forceSource = this.primaryProgramDefinition.program.phases[0].forceSource;

    if (calibration && forceSource) {
      if (this.sensorForcePlugin) {
        this.sensorForcePlugin.signalsAdditionalData[forceSource].positiveMaxValue.value =
          calibration.mvc?.[forceSource] ?? 1;
        this.sensorForcePlugin.signalsAdditionalData[forceSource].negativeMaxValue.value =
          calibration.mvc?.[`${forceSource}Negative`] ?? 1;

        this.sensorForcePlugin.signalsAdditionalData[forceSource].positiveThreshold.value =
          calibration.threshold?.[forceSource] ?? 0.5;
        this.sensorForcePlugin.signalsAdditionalData[forceSource].negativeThreshold.value =
          calibration.threshold?.[`${forceSource}Negative`] ?? 0.5;
      }
    }
    this.initializeTriggers();
  }

  get programData() {
    return this._programData;
  }

  get exerciseData() {
    return this._exerciseData;
  }

  get basingData() {
    return this._basingData;
  }

  get finished() {
    return this._programData.cam.finished;
  }

  get triggers(): readonly Trigger[] {
    return this._triggers;
  }

  get additionalTriggers(): readonly Trigger[] {
    return this._triggers.filter(t => this.triggerForceSensors.findIndex(n => n === t.name) >= 0);
  }

  get currentBasingData() {
    return this._basingData.peek();
  }

  get settings() {
    return this._settingsBuilder;
  }

  get recordings() {
    return this._recordings;
  }

  get definition() {
    return this._exerciseDefinition as CAMExerciseDefinition;
  }

  get sensorForcePlugin() {
    return this._sensorForcePlugin;
  }

  get remotePlugin() {
    return this._remotePlugin;
  }

  get primaryProgramDefinition() {
    return this._exerciseDefinition.cam[this._primaryCAMMotor]! as GeneratedCAMProgramDefinitionPrimary;
  }

  private get synchronizedProgramDefinition() {
    return this._synchronizedCAMMotor
      ? (this._exerciseDefinition.cam[this._synchronizedCAMMotor] as CAMProgramDefinitionSynchronized)
      : null;
  }

  private get requiredForceSensors() {
    const forceSource = this.definition.cam[this._primaryCAMMotor]?.program?.phases[0].forceSource;
    const forceTriggerSource = this.definition.cam[this._primaryCAMMotor]?.program?.phases[0].forceTriggerSource;
    return {
      primary: forceSource,
      ...(forceTriggerSource ? { secondary: forceTriggerSource } : {}),
    };
  }

  private get triggerForceSensors() {
    const forceTriggerSource = this.definition.cam[this._primaryCAMMotor]?.program?.phases[0].forceTriggerSource;
    return forceTriggerSource ? [forceTriggerSource] : [];
  }

  private initializeTriggers() {
    // we have to maintain the initial reference of this._triggers in order to properly clear not used signals inside TriggerThresholdEMG every time we create new set of triggers
    this._triggers.length = 0;

    if (this._sensorForcePlugin) {
      const activeMovementDirection =
        this._exerciseDefinition.cam[this._primaryCAMMotor]?.program?.phases[0].activeMovementDirection;

      for (const entry of Object.entries(this.requiredForceSensors)) {
        const [forceSensorType, forceSesorName] = entry;
        if (forceSesorName) {
          const createPositivetrigger = () => {
            if (!this._sensorForcePlugin) {
              return;
            }

            const trigger = new TriggerThresholdForce(
              this._sensorForcePlugin,
              forceSesorName,
              this.triggerForceSensors.find(t => t === forceSesorName) ? 'additional' : null,
            );
            const calibration = this.calibrationData?.['force-calibration'] as ForceCalibrationData | undefined;
            trigger.setValue(calibration?.mvc[forceSesorName] ?? 1);
            trigger.setThreshold(calibration?.threshold[forceSesorName] ?? 0.5);
            this._triggers.push(trigger);
          };

          const createNegativeTrigger = () => {
            if (!this._sensorForcePlugin) {
              return;
            }
            const trigger = new TriggerThresholdForce(
              this._sensorForcePlugin,
              forceSesorName,
              this.triggerForceSensors.find(t => t === forceSesorName) ? 'additional' : null,
              `${forceSesorName}Negative`,
            );
            const calibration = this.calibrationData?.['force-calibration'] as ForceCalibrationData | undefined;
            trigger.setValue(-(calibration?.mvc[`${forceSesorName}Negative`] ?? 1));
            trigger.setThreshold(calibration?.threshold[`${forceSesorName}Negative`] ?? 0.5);
            this._triggers.push(trigger);
          };

          switch (forceSensorType) {
            case 'primary':
              switch (activeMovementDirection) {
                case 'both':
                  createPositivetrigger();
                  if (!this._sensorForcePlugin.isUnidirectional(forceSesorName)) {
                    createNegativeTrigger();
                  }

                  break;
                case 'toMax':
                  createPositivetrigger();
                  break;
                case 'toMin':
                  createNegativeTrigger();
                  break;
              }
              break;
            case 'secondary':
              createPositivetrigger();
              if (!this._sensorForcePlugin.isUnidirectional(forceSesorName)) {
                createNegativeTrigger();
              }
              break;
          }
        }
      }
    }
  }

  init() {
    if (this._programData.cam.active.peek()) {
      CAMExercise.logger.debug('start', 'Program is already started');
      return;
    }

    this._camPrimaryFeature = this.session.activate(ExoCAMFeature, { motor: this._primaryCAMMotor });

    if (this._synchronizedCAMMotor) {
      this._camSynchronizedFeature = this.session.activate(ExoMotorFeature, { name: this._synchronizedCAMMotor });
      const maxSpeed = this.session.options.motor?.[this._synchronizedCAMMotor].maxSpeed ?? null;

      if (!maxSpeed) {
        throw new Error(`Missing maxSpeed for motor '${this._synchronizedCAMMotor}'.`);
      }

      this._camSynchronizedFeature.setMaxSpeed(maxSpeed);
    }

    this._passiveMotorFeatures = Object.entries(this._exerciseDefinition.cam)
      .filter(([_, v]) => isGeneratedCAMProgramDefinitionPassive(v))
      .map(([k, _]) => this.session.activate(ExoMotorFeature, { name: k }));
    const requiredSensors = Object.keys(this.primaryProgramDefinition.program.phases[0].coupling.force!);

    this._remotePlugin.init();

    this.initializeFeatureCallbacks();
    this._sensorForcePlugin = new SensorForcePlugin(this.session, requiredSensors);
    this._sensorForcePlugin.onSensorData = this.handleSensorData.bind(this);
    this._sensorForcePlugin.onSensorAdditionalData = this.handleSensorAdditionalData.bind(this);
    this._programData.cam.initialized.value = true;
    this._programData.cam.active.value = true;
    this._programData.cam.finished.value = false;

    this._basingData.value = {
      ...this._basingData.peek(),
      active: true,
    };

    this._programData.cam.primaryMotor.value = this._primaryCAMMotor;
    this._programData.cam.synchronizedMotor.value = this._synchronizedCAMMotor;
    this.initializeInterval();
  }

  handleSensorAdditionalData(sensorName: SensorsName) {
    const forceSource = this.primaryProgramDefinition.program.phases[0].forceSource;

    if (forceSource === sensorName && this._programData.cam.running.peek()) {
      if (this.additionalTriggers.every(t => t.isTriggered())) {
        this.setExerciseCoupling(this._primaryCAMMotor, sensorName, this._extensionType, false);
      }
    }
  }

  setProgram(options?: { update?: boolean }) {
    if (!this._camPrimaryFeature) {
      throw new Error('Cannot set CAM program without activated CAM feature');
    }

    this._settingsBuilder.updateDefinition(this._exerciseDefinition);

    this._exerciseData.totalDuration.value =
      this._exerciseDefinition.cam[this._primaryCAMMotor]?.program?.phases[0].time ?? 0;

    this._exerciseData.totalRepetition.value =
      this._exerciseDefinition.cam[this._primaryCAMMotor]?.program?.phases[0].repetitions ?? 0;

    if (options?.update) {
      this._camPrimaryFeature.updateProgram(this.primaryProgramDefinition.program);
    } else {
      this._camPrimaryFeature.setProgram(this.primaryProgramDefinition.program);
    }
  }

  supports(_feature: ExerciseFeature): boolean {
    return false;
  }

  private setExerciseCoupling(
    motorName: MotorPlacement,
    forceName: SensorsName,
    extensionType: ExtensionType | null,
    synchronized = false,
  ) {
    const isTorqueExercise = this.definition.type === 'cam-torque';
    const isIsokineticExercise = this.definition.type === 'cam-isokinetic';
    const isGameExercise = this.definition.type === 'cam-game';
    const isCamTurnKeyExercise = this.definition.type === 'cam-turn-key';

    const activeMovementDirection =
      this._exerciseDefinition.cam[this._primaryCAMMotor]?.program?.phases[0].activeMovementDirection;
    if (!activeMovementDirection) {
      throw new Error('Active movement direction is not set!');
    }
    let positiveDeadband = 0;
    let negativeDeadband = 0;
    let positiveMaxValue = 0;
    let negativeMaxValue = 0;

    const forceSource = this.primaryProgramDefinition.program.phases[0].forceSource;

    if (!forceSource) {
      throw new Error('sensorNameForDeadbandSource is undefined');
    }

    positiveDeadband =
      (this.sensorForcePlugin?.signalsAdditionalData[forceSource].positiveMaxValue.value as number) *
      (this.sensorForcePlugin?.signalsAdditionalData[forceSource].positiveThreshold.value as number);

    negativeDeadband =
      (this.sensorForcePlugin?.signalsAdditionalData[forceSource].negativeMaxValue.value as number) *
      (this.sensorForcePlugin?.signalsAdditionalData[forceSource].negativeThreshold.value as number);

    positiveMaxValue = this.sensorForcePlugin?.signalsAdditionalData[forceSource].positiveMaxValue.value as number;
    negativeMaxValue = this.sensorForcePlugin?.signalsAdditionalData[forceSource].negativeMaxValue.value as number;

    if (!synchronized) {
      let currentExtensionSensitivity, currentFlexionSensitivity;
      if (isTorqueExercise) {
        const maxSpeed = this._exerciseDefinition.cam[this._primaryCAMMotor]?.program?.phases[0].maxSpeed ?? 10;
        const calculatedFlexionSensitivity = calculateSensitivity(positiveMaxValue - positiveDeadband, maxSpeed);
        const calculatedExtensionSensitivity = calculateSensitivity(negativeMaxValue - negativeDeadband, maxSpeed);
        if (!this.calibrationData) {
          throw new Error('calibrationData should be here');
        }
        const exerciseParameters = this.calibrationData['basing-settings']?.exerciseParameters as {
          'cam.0.activeMovementDirection': 'both' | 'toMin' | 'toMax';
        };
        const activeMovementDirection = exerciseParameters['cam.0.activeMovementDirection'];
        switch (activeMovementDirection) {
          case 'both': {
            this._camPrimaryFeature?.motorFeature.setCoupling('force', forceName, {
              positive: {
                sensitivity: calculatedFlexionSensitivity,
                deadband: positiveDeadband,
              },
              negative: {
                sensitivity: calculatedExtensionSensitivity,
                deadband: negativeDeadband,
              },
            });
            break;
          }
          case 'toMin': {
            this._camPrimaryFeature?.motorFeature.setCoupling('force', forceName, {
              positive: {
                sensitivity: this._sensorForcePlugin?.isUnidirectional(forceName)
                  ? -calculatedFlexionSensitivity
                  : calculatedExtensionSensitivity,
                deadband: this._sensorForcePlugin?.isUnidirectional(forceName) ? positiveDeadband : negativeDeadband,
              },
              negative: {
                sensitivity: this._sensorForcePlugin?.isUnidirectional(forceName) ? 0 : calculatedExtensionSensitivity,
                deadband: this._sensorForcePlugin?.isUnidirectional(forceName) ? 0 : negativeDeadband,
              },
            });
            break;
          }
          case 'toMax': {
            this._camPrimaryFeature?.motorFeature.setCoupling('force', forceName, {
              positive: {
                sensitivity: calculatedFlexionSensitivity,
                deadband: positiveDeadband,
              },
              negative: {
                sensitivity: 0,
                deadband: 0,
              },
            });
            break;
          }
        }
      }

      if (isIsokineticExercise || isCamTurnKeyExercise || isGameExercise) {
        const force = this.primaryProgramDefinition.program.phases[0].coupling.force as {
          [key: string]: { sensitivity: number };
        };
        const definedSensitivity = force[forceName].sensitivity;

        if (!definedSensitivity) {
          throw new Error('Sensitivity is not passed in program definition');
        }

        currentExtensionSensitivity = definedSensitivity;
        currentFlexionSensitivity = definedSensitivity;
      }
      if (currentExtensionSensitivity && currentFlexionSensitivity) {
        if (this.deviceType === 'meissa-ot') {
          if (!extensionType) {
            throw new Error(
              `Cannot set coupling for device ${this.deviceType} without valid extension ${extensionType}`,
            );
          }
          CAMExercise.logger.debug('setExerciseCoupling', `setting extension type to ${extensionType}`);
          this._camPrimaryFeature?.motorFeature.setCoupling('force', forceName, {
            positive: {
              sensitivity: currentFlexionSensitivity,
              deadband: positiveDeadband,
            },
            negative: {
              sensitivity: currentExtensionSensitivity,
              deadband: negativeDeadband,
            },
          });
        } else if (this.deviceType === 'sidra-leg') {
          this._camPrimaryFeature?.motorFeature.setCoupling('force', forceName, {
            positive: {
              sensitivity: currentFlexionSensitivity,
              deadband: positiveDeadband,
            },
            negative: {
              sensitivity: currentExtensionSensitivity,
              deadband: negativeDeadband,
            },
          });
        }

        if (!this._sensorForcePlugin?.isUnidirectional(forceName)) {
          this._camPrimaryFeature?.motorFeature.setCoupling('force', forceName, {
            positive: {
              sensitivity: currentFlexionSensitivity,
              deadband: positiveDeadband,
            },
            negative: {
              sensitivity: currentExtensionSensitivity,
              deadband: negativeDeadband,
            },
          });
        } else {
          // set negative sensitivity if activeMovementDirection === 'toMin' (counterclockwise)
          // to achieve active movement if a sensor is unidirectional
          this._camPrimaryFeature?.motorFeature.setCoupling('force', forceName, {
            sensitivity: currentFlexionSensitivity * (activeMovementDirection === 'toMin' ? -1 : 1),
            deadband: positiveDeadband,
          });
        }
      }
    }

    if (synchronized) {
      const motor = this._primaryCAMMotor as 'knee' | 'ankle';
      const primaryMotorActiveRange =
        this.calibrationData?.[`leg-basing-max-flexion-and-extension-measurement-passive-${motor}`];

      if (typeof primaryMotorActiveRange?.min !== 'number' || typeof primaryMotorActiveRange?.max !== 'number') {
        throw new Error('Primary motor active range is wrong');
      }

      this._camSynchronizedFeature?.setCoupling('motor', this._primaryCAMMotor, {
        leadingRange: [primaryMotorActiveRange.min, primaryMotorActiveRange.max],
      });
    }
  }

  play() {
    const activeMovementDirection =
      this._exerciseDefinition.cam[this._primaryCAMMotor]?.program?.phases[0].activeMovementDirection;

    if (this.finished.peek()) {
      CAMExercise.logger.warn('play', 'Cannot play program that was finished');
      return;
    }

    if (!this._programData.cam.active.peek()) {
      CAMExercise.logger.warn('play', 'Cannot play program that is ended or not yet started');
      return;
    }

    if (!this._camPrimaryFeature) {
      throw new Error('Cannot play program without activated CAM feature');
    }

    if (!this.currentBasingData.primaryCamRange) {
      throw new Error('Cannot play program without set CAM range for primary motor');
    }

    if (this._synchronizedCAMMotor && !this.currentBasingData.synchronizedCamRange) {
      throw new Error('Cannot play program without set CAM range for secondary motor');
    }

    if (!this.calibrationData) {
      throw new Error('Cannot play program on during calibration');
    }

    if (this.currentBasingData.primaryCamRange) {
      this.setMotorRange(
        {
          min: this.currentBasingData.primaryCamRange?.min,
          max: this.currentBasingData.primaryCamRange?.max,
        },
        this._primaryCAMMotor,
      );
    }

    if (this.currentBasingData.synchronizedCamRange && this._synchronizedCAMMotor) {
      this.setMotorRange(
        { min: this.currentBasingData.synchronizedCamRange.min, max: this.currentBasingData.synchronizedCamRange.max },
        this._synchronizedCAMMotor,
      );
    }

    this.initializeInterval();

    this._camPrimaryFeature.start();

    if (this._recorderController?.started) {
      this._recorderController?.resume();
    } else {
      this._recorderController?.start();
    }

    if (!exerciseActionTracker.timePoints.exercise?.start) {
      exerciseActionTracker.timePoints.exercise.start = new Date();
    }
    exerciseActionTracker.add('activity', 'play');

    const primaryCamRange = this.currentBasingData.primaryCamRange;

    if (primaryCamRange && this._synchronizedCAMMotor) {
      this._camSynchronizedFeature?.setCoupling('motor', this._primaryCAMMotor, {
        leadingRange: [primaryCamRange.min, primaryCamRange.max],
      });
    }

    this._programData.cam.running.value = true;

    const forceName = this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].forceSource;

    if (forceName) {
      this.setExerciseCoupling(this._primaryCAMMotor, forceName, this._extensionType, false);
      if (this._synchronizedCAMMotor) {
        this.setExerciseCoupling(this._synchronizedCAMMotor, forceName, this._extensionType, true);
      }
    }
    this.startSensorForceMeasurement();
    if (
      this.definition.type === 'cam-turn-key' &&
      (activeMovementDirection === 'both' || this._programData.cam.currentDirection.peek() === activeMovementDirection)
    ) {
      // after returning from the settings, device is moving when
      // extension threshold is not reached so we holding a device here
      this._camPrimaryFeature?.hold();
    }
  }

  pause(options?: { endPause?: boolean }) {
    if (!this._programData.cam.active.peek()) {
      CAMExercise.logger.warn('pause', 'Cannot pause program that is ended or not yet started');
      return;
    }
    if (!this._camPrimaryFeature) {
      throw new Error('Cannot pause program without activated CAM feature');
    }

    this._camPrimaryFeature.stop();
    this._camSynchronizedFeature?.stop();

    this._recorderController?.pause();

    if (!options?.endPause) {
      exerciseActionTracker.add('activity', 'pause');
    }
    // This line fixes the problem where, during exercise, if we click on settings and then return to the exercise, holdStatus would be released without this line, causing the device position to move despite that should stand.
    this._holdStatus = 'held';
    this._programData.cam.running.value = false;
  }

  end(reason: FinishedReason = 'exerciseFinished') {
    if (!this._programData.cam.active.peek()) {
      CAMExercise.logger.debug('end', 'Cannot end program that is not started');
      return;
    }
    if (this._programData.cam.finished.peek()) {
      CAMExercise.logger.debug('end', 'Program is already finished');
      return;
    }
    if (!this._camPrimaryFeature) {
      throw new Error('Cannot end program without activated CAM feature');
    }

    this.pause({ endPause: true });
    this._sensorForcePlugin?.setReliefMode(false);

    if (this._recorderController) {
      this._recordings = this._recorderController.stop();
    }

    this._programData.cam.finishedReason.value = reason;
    this._programData.cam.running.value = false;
    this._programData.cam.active.value = false;
    this._programData.cam.finished.value = true;
    this.stopSensorForceMeasurement();

    this._basingData.value = {
      ...this._basingData.peek(),
      active: false,
    };
    this._holdStatus = null;
  }

  destroy() {
    this.destroyInterval();

    if (!this._camPrimaryFeature) {
      CAMExercise.logger.debug('destroy', 'CAM Feature is already disposed');
      return;
    }

    this._programData.cam.initialized.value = false;

    this.end();
    this._camPrimaryFeature.dispose();
    this._camPrimaryFeature = null;
    this._camSynchronizedFeature?.dispose();
    this._camSynchronizedFeature = null;
    this._passiveMotorFeatures.forEach(v => v.dispose());
    this._sensorForcePlugin?.dispose();
    this._sensorForcePlugin = null;
    this._remotePlugin.dispose();
    this._recorderController = null;
    this.sensorForcePlugin?.dispose();
  }

  setExtensionType(extensionType: ExtensionType) {
    this._extensionType = extensionType;
  }

  setMotorRange(range: DeepReadonly<MotorRange>, motorId: MotorPlacement) {
    if (motorId === this._primaryCAMMotor) {
      if (!this._camPrimaryFeature) {
        throw new Error('Cannot set CAM range for primary motor without activated feature');
      }

      this._basingData.value = { ...this.currentBasingData, primaryCamRange: { ...range } };
      this._camPrimaryFeature.setRange(range.min, range.max);
    } else if (motorId == this._synchronizedCAMMotor) {
      if (!this._camSynchronizedFeature) {
        throw new Error('Cannot set CAM range for synchronized motor without activated feature');
      }
      this._basingData.value = { ...this.currentBasingData, synchronizedCamRange: { ...range } };
      this._camSynchronizedFeature.setRange(range.min, range.max);
    } else {
      const motorFeature = this._passiveMotorFeatures.find(v => v.name === motorId);

      if (!motorFeature) {
        throw new Error(`Motor '${motorId}' is not available on this exercise`);
      }

      CAMExercise.logger.info('setMotorRange', 'Setting motor range for passive motor.');
      motorFeature.setRange(range.min, range.max);
    }
  }

  getMotorRange(motorId: MotorPlacement) {
    if (motorId === this._primaryCAMMotor) {
      return this.currentBasingData.primaryCamRange ?? null;
    }

    if (motorId === this._synchronizedCAMMotor) {
      return this.currentBasingData.synchronizedCamRange ?? null;
    }

    throw new Error(`Motor ${motorId} is not available for this CAM exercise.`);
  }

  getMotor(motorId: MotorPlacement) {
    if (motorId === this._primaryCAMMotor) {
      return this._camPrimaryFeature?.motorFeature ?? null;
    }

    if (motorId === this._synchronizedCAMMotor) {
      return this._camSynchronizedFeature ?? null;
    }

    throw new Error(`Motor ${motorId} is not available for this CAM exercise.`);
  }

  tare() {
    if (!this._sensorForcePlugin) {
      throw new Error('Cannot start tare because force sensor is not initialized');
    }
    this._sensorForcePlugin.onTareCompleted = _ => {
      this._basingData.value = {
        ...this.currentBasingData,
        tareStatus: 'completed',
      };
    };
    this._sensorForcePlugin.startTare();
    this._basingData.value = {
      ...this.currentBasingData,
      tareStatus: 'inprogress',
    };
  }

  private handleSensorData = (sensorData: SensorChange) => {
    this._programData.cam.sensorsData.value = { ...sensorData };
    this._programData.cam.currentDirection.value = this._camPrimaryFeature?.currentDirection;
    const activeMovementDirection =
      this._exerciseDefinition.cam[this._primaryCAMMotor]?.program?.phases[0].activeMovementDirection;

    if (this._programData.cam.running.peek()) {
      const forceSource = this.primaryProgramDefinition.program.phases[0].forceSource;
      if (!forceSource) {
        throw new Error('Cannot set sensor name for deadbandSource');
      }

      /**
       * Trigger ExoCAMFeautre when:
       * * The feature can be triggered
       * * level of force must be below a threshold
       */
      if (this._camPrimaryFeature?.canTrigger()) {
        if (this._triggers.filter(t => t.name === forceSource).every(t => !t.isTriggered())) {
          this._camPrimaryFeature.trigger();
        }
      }

      // CAM-TURN-KEY handling
      const additionalTriggers = this.additionalTriggers;
      if (!additionalTriggers.length) {
        return;
      }
      // force release for the initial moving to start position phase
      if (additionalTriggers.every(t => t.isTriggered())) {
        if (this._holdStatus !== 'released') {
          this._camPrimaryFeature?.release();
          this.setExerciseCoupling(this._primaryCAMMotor, forceSource, this._extensionType, false);
          this._holdStatus = 'released';
        }
      } else {
        if (
          this._holdStatus !== 'held' &&
          (activeMovementDirection === 'both' ||
            this._programData.cam.currentDirection.peek() === activeMovementDirection)
        ) {
          this._camPrimaryFeature?.hold();
          this._holdStatus = 'held';
        }
      }
    }
  };

  startSensorForceMeasurement() {
    if (!this._sensorForcePlugin) {
      throw new Error('Cannot start sensors measurement because force sensor is not initialized');
    }
    this._sensorForcePlugin.startSensorForceMeasurement();
  }

  stopSensorForceMeasurement() {
    if (!this._sensorForcePlugin) {
      throw new Error('Cannot stop sensors measurement because force sensor is not initialized');
    }
    this._sensorForcePlugin.stopSensorForceMeasurement();
  }

  private initializeInterval() {
    if (this._timeInterval !== null) {
      return;
    }
    this._timeInterval = setInterval(this.onTimeChange.bind(this), Timers.EXERCISE_DATA_REFRESH_INTERVAL);
  }

  private destroyInterval() {
    if (this._timeInterval !== null) {
      clearInterval(this._timeInterval);
      this._timeInterval = null;
    }
  }

  private initializeFeatureCallbacks() {
    if (!this._camPrimaryFeature) {
      throw new Error('Cannot initialize features callbacks without fully activated features');
    }

    const onPrimaryAngleCallback = (values: Float32Array) => {
      const primaryAngle = values.at(-1);
      this.updateAngleStateData(this._primaryCAMMotor, primaryAngle);
    };

    const onSynchronizedAngleCallback = (values: Float32Array) => {
      if (!this._synchronizedCAMMotor || !this._camSynchronizedFeature) {
        throw new Error('Cannot run synchronized angle callback without synchronized motor');
      }

      const angle = values.at(-1);

      this.updateAngleStateData(this._synchronizedCAMMotor, angle);
    };

    const onRepetitionCallback = (repetition: number) => {
      if (!repetition) {
        return;
      }

      const phases = this.primaryProgramDefinition.program.phases;
      if (this._currentPhaseIndex >= phases.length) {
        this.end();
        return;
      }

      if (this._currentPhaseIndex < phases.length) {
        this._currentPhaseRepetitionKey = repetition;
        this._programData.cam.currentRepetition.value = repetition;
      }
    };

    const onFinishCallback = () => {
      this.end();
    };

    const onInterruptCallback = () => {
      this.pause();
    };

    this._camPrimaryFeature.onAngle = onPrimaryAngleCallback;
    this._camPrimaryFeature.onRepetition = onRepetitionCallback;
    this._camPrimaryFeature.onFinish = onFinishCallback;
    this._camPrimaryFeature.onInterrupt = onInterruptCallback;

    if (this._camSynchronizedFeature) {
      this._camSynchronizedFeature.onAngle = onSynchronizedAngleCallback;
    }

    this._passiveMotorFeatures.forEach(
      v =>
        (v.onAngle = (values: Float32Array) => {
          this.updateAngleStateData(v.name as MotorPlacement, values.at(-1));
        }),
    );
    this.initializeInterval();
  }

  private onTimeChange() {
    const phases = this.primaryProgramDefinition.program.phases ?? [];
    if (this._currentPhaseIndex < phases.length) {
      const currentDuration = this._camPrimaryFeature?.currentDuration ?? 0;
      if (Math.round(currentDuration) !== Math.round(this._programData.cam.currentDuration.peek())) {
        this._programData.cam.currentDuration.value = currentDuration;
      }
    }
  }

  private updateAngleStateData(motor: MotorPlacement, data: number | undefined) {
    if (typeof data === 'number') {
      switch (motor) {
        case this._primaryCAMMotor:
          this._programData.cam.primaryAngle.value = data;
          break;
        case this._synchronizedCAMMotor:
          this._programData.cam.synchronizedAngle.value = data;
          break;
      }
    }
  }
}
