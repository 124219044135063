import { useEffect, useMemo, useRef } from 'react';
import { ExoMotorFeature } from '@egzotech/exo-session/features/motor';
import { MotorPlacement } from 'libs/exo-session-manager/core';

import { useDevice } from '../providers/ExoGlobalProvider';

import { MotorBasingActions } from './useMultiMotorBasing';

export const useMotorBasing = <T extends MotorPlacement>(motor: T): MotorBasingActions<T> => {
  const motorFeature = useRef<ExoMotorFeature | null>(null);
  const { session } = useDevice();

  useEffect(() => {
    if (!session) {
      motorFeature.current = null;
      return;
    }

    motorFeature.current = session.activate(ExoMotorFeature, { name: motor });

    return () => {
      motorFeature.current?.dispose();
      motorFeature.current = null;
    };
  }, [motor, session]);

  return useMemo(() => {
    return {
      getMotorRange: () => ({ min: motorFeature.current!.range[0], max: motorFeature.current!.range[1] }),
      setFreeRoamCoupling: (preset: string) => motorFeature.current?.setFreeRoamCoupling(preset),
      setMotorRange: range => motorFeature.current?.setRange(range.min, range.max),
      stopMotor: () => motorFeature.current?.stop(),
    };
  }, [motorFeature]);
};
