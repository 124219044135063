import { useParams } from 'react-router-dom';
import { __ } from 'helpers/i18n';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useDevice } from 'libs/exo-session-manager/react';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiPaths, ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import { SelectData } from 'types';

import { errorNotification, successNotification } from '../../helpers/handleNotifications';
import {
  getAvailableDeviceTypes,
  getAvailableFilters,
  getFavoriteTrainingList,
  getTrainingList,
  setFavoriteCategory as setTrainingFavoriteCategory,
  setFavoriteTraining,
  SetFavoriteTrainingArgs,
  setFilters as setTrainingFilters,
  setPatientId as setTrainingPatientId,
  setSearch as setTrainingSearch,
  setTrainingList,
  TrainingFilterType,
} from '../../slices/trainingListSlice';

interface FilterItem {
  id: string;
  name: string;
}

export interface FilterItemWithType extends FilterItem {
  type: TrainingFilterType;
}

interface LoadTrainingsSetup {
  filters?: ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query'];
  isFavoriteTraining?: boolean;
  page?: number;
}

interface UseTrainingListReturn {
  loadTrainings: (setup?: { filters?: LoadTrainingsSetup['filters']; isFavoriteTraining?: boolean }) => void;
  loadAvailableDeviceTypes: () => void;
  loadAvailableFilters: () => void;
  getOptionsFromFilter: (filterItems?: FilterItem[], beginningTranslationText?: string) => SelectData[];
  setSearch: (search: string) => void;
  setFilter: (filterIds: string, filterType: TrainingFilterType) => void;
  setPatientId: () => void;
  setFavoriteCategory: (isFavoriteTraining: boolean) => void;
  removeFilter: (filterItem: FilterItemWithType) => void;
  getAllCurrentFilters: (
    availableFilters: ExoClinicBackendOpenApiSchemas['TrainingTemplateFiltersResponseDto'],
  ) => FilterItemWithType[];
  getCurrentSearch: () => string | undefined;
  getCurrentPatientId: () => string | undefined;
  setFavoriteInTraining: (templateId: string, favorite: boolean) => void;
  loadMoreTrainings: (page: number, isFavoriteTraining?: boolean) => void;
}

// Please do not use, returns unpredictable functions, ex. '/views/owner/clinic/statistics-report/_containers/StatisticsContent.tsx'
export const useTrainingList = (): UseTrainingListReturn => {
  const { patientId } = useParams();
  const { currentFilters, isFavorite, availableDeviceTypes } = useAppSelector(state => state.trainingList);
  const dispatch = useAppDispatch();
  const { selectedDevice } = useDevice();

  const getCurrentPatientId = () => {
    if (!patientId) {
      errorNotification('error.noUserId');
      return '';
    }
    return patientId;
  };

  const loadTrainings = (setup?: LoadTrainingsSetup) => {
    const setupFilters = { ...(setup?.filters ?? currentFilters) };
    const setupFavorite = setup?.isFavoriteTraining ?? isFavorite;

    const deviceUuid = availableDeviceTypes?.find(device => device.name.replace('_', '-') === selectedDevice?.type)?.id;
    if (!deviceUuid) {
      dispatch(setTrainingList([]));
      return;
    }
    setupFilters.deviceTypeId = [deviceUuid];
    setupFilters.userProfileId = getCurrentPatientId();
    setupFilters.pageNumber = setup?.page ?? 0;
    if (setupFavorite) {
      dispatch(getFavoriteTrainingList(setupFilters));
      return;
    }

    dispatch(getTrainingList(setupFilters));
  };

  const loadMoreTrainings = (page: number, isFavoriteTraining?: boolean) => {
    loadTrainings({
      page,
      isFavoriteTraining,
    });
  };

  const loadAvailableDeviceTypes = () => {
    dispatch(getAvailableDeviceTypes());
  };

  const loadAvailableFilters = () => {
    dispatch(getAvailableFilters());
  };

  const getOptionsFromFilter = (filterItems?: FilterItem[], beginningTranslationText?: string): SelectData[] => {
    if (!filterItems) {
      return [];
    }

    return filterItems.map(item => ({ label: __(`${beginningTranslationText ?? ''}${item.name}`), value: item.id }));
  };

  const addFilterToArray = (array: string[], filter: string) => {
    if (array.includes(filter)) {
      return array;
    }
    return [...array, filter];
  };

  const getFilterObjectAfterAdd = (filterId: string, filterType: TrainingFilterType) => {
    switch (filterType) {
      case TrainingFilterType.SET:
        return {
          classificationPackageIds: addFilterToArray(currentFilters.classificationPackageId ?? [], filterId),
        };
      case TrainingFilterType.TRAINING:
        return { trainingTypeIds: addFilterToArray(currentFilters.trainingTypeId ?? [], filterId) };
      case TrainingFilterType.BODY:
        return { bodyPartIds: addFilterToArray(currentFilters.bodyPartId ?? [], filterId) };
      case TrainingFilterType.ICD:
        return { icd10DiagnoseIds: addFilterToArray(currentFilters.icd10DiagnoseId ?? [], filterId) };
    }
  };

  const setFilter = (filterId: string, filterType: TrainingFilterType) => {
    const filter = getFilterObjectAfterAdd(filterId, filterType);
    const filters = { ...currentFilters, ...filter };

    loadTrainings({ filters });
    dispatch(setTrainingFilters(filters));
  };

  const getFilterObjectAfterRemove = (filterItem: FilterItemWithType) => {
    const { classificationPackageId, bodyPartId, trainingTypeId, icd10DiagnoseId } = currentFilters;
    const { id, type } = filterItem;

    switch (type) {
      case TrainingFilterType.SET:
        return {
          classificationPackageIds: classificationPackageId?.filter(item => item !== id),
        };
      case TrainingFilterType.TRAINING:
        return { trainingTypeIds: trainingTypeId?.filter(item => item !== id) };
      case TrainingFilterType.BODY:
        return { bodyPartIds: bodyPartId?.filter(item => item !== id) };
      case TrainingFilterType.ICD:
        return { icd10DiagnoseIds: icd10DiagnoseId?.filter(item => item !== id) };
    }
  };

  const removeFilter = (filterItem: FilterItemWithType) => {
    const filter = getFilterObjectAfterRemove(filterItem);
    const filters = { ...currentFilters, ...filter };

    loadTrainings({ filters });
    dispatch(setTrainingFilters(filters));
  };

  const setSearch = async (search: string) => {
    if (search === undefined || search === currentFilters.name) {
      return;
    }

    dispatch(setTrainingSearch(search));
  };

  const getCurrentSearch = () => currentFilters.name;

  const getCurrentFiltersAllData = (
    currentFilters: string[],
    availableFilters: FilterItem[],
    type: TrainingFilterType,
    beginningTranslationText?: string,
  ): FilterItemWithType[] => {
    const filtersData = availableFilters.filter(availableFilter =>
      currentFilters.find(currentFilter => currentFilter === availableFilter.id),
    );

    return filtersData.map(filter => ({ ...filter, type, name: __(`${beginningTranslationText}${filter.name}`) }));
  };

  const getAllCurrentFilters = (
    availableFilters: ExoClinicBackendOpenApiSchemas['TrainingTemplateFiltersResponseDto'],
  ): FilterItemWithType[] => {
    const { trainingTypeId } = currentFilters;
    const { trainingTypes } = availableFilters;

    return [
      ...getCurrentFiltersAllData(trainingTypeId ?? [], trainingTypes, TrainingFilterType.TRAINING, 'training.types.'),
    ];
  };

  const setPatientId = () => {
    dispatch(setTrainingPatientId(getCurrentPatientId()));
  };

  const afterSuccessSetFavorite = (notification: string, setFavoriteArgs: SetFavoriteTrainingArgs) => {
    successNotification(notification);
    dispatch(setFavoriteTraining(setFavoriteArgs));
  };

  const setFavoriteInTraining = async (templateId: string, favorite: boolean) => {
    const body = {
      userProfileId: getCurrentPatientId(),
      trainingTemplateId: templateId,
    };

    const setFavoriteArgs: SetFavoriteTrainingArgs = {
      favorite,
      templateId,
    };

    if (favorite) {
      await exoClinicApi.trainingTemplates
        .addFavorite(body)
        .then(() => afterSuccessSetFavorite('training.addFavoriteCorrect', setFavoriteArgs));
      return;
    }

    await exoClinicApi.trainingTemplates
      .removeFavorite(body)
      .then(() => afterSuccessSetFavorite('training.removeFavoriteCorrect', setFavoriteArgs));
  };

  const setFavoriteCategory = (isFavoriteTraining: boolean) =>
    dispatch(setTrainingFavoriteCategory(isFavoriteTraining));

  return {
    loadTrainings,
    loadAvailableDeviceTypes,
    loadAvailableFilters,
    getOptionsFromFilter,
    setFilter,
    removeFilter,
    getAllCurrentFilters,
    setSearch,
    setPatientId,
    setFavoriteCategory,
    getCurrentSearch,
    getCurrentPatientId,
    setFavoriteInTraining,
    loadMoreTrainings,
  };
};
