import { ExoElectrostimFeature } from '@egzotech/exo-session/features/electrostim';
import { signal } from 'helpers/signal';

import { Timer, TimerState } from './TimerGroup';

export class EMSTimeController implements Timer {
  private _emsFeature: ExoElectrostimFeature | null = null;
  private _state: TimerState = 'idle';
  private _intervalId: NodeJS.Timeout | null = null;

  readonly currentProgramDuration = signal(0, 'EMSTimeController.currentProgramDuration');
  readonly currentRepetitionDuration = signal(0, 'EMSTimeController.currentRepetitionDuration');
  readonly currentPhaseDuration = signal(0, 'EMSTimeController.currentPhaseDuration');

  /** 0.5s */
  readonly INTERVAL_TIME = 500;

  get state() {
    return this._state;
  }

  setup(options: { emsFeature: ExoElectrostimFeature }) {
    this._emsFeature = options.emsFeature;
  }

  play() {
    if (!this._emsFeature) {
      throw new Error('Cannot run electrostim program without EMS Feature');
    }
    if (this._state === 'running') {
      // do nothing if already running
      return;
    }
    this._intervalId = setInterval(() => this.tick(), this.INTERVAL_TIME);
    this.tick();
    this._state = 'running';
  }

  resume() {
    if (this._state === 'finish') {
      // reset timer if it is completed
      this.reset();
    }
    this.play();
  }

  pause() {
    if (this._state !== 'running') {
      // do nothing if not running
      return;
    }
    this._state = 'paused';
    if (this._intervalId !== null) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
  }

  dispose() {
    this.reset();
  }

  private reset() {
    this._state = 'idle';
    if (this._intervalId !== null) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
  }

  private tick() {
    if (!this._emsFeature) {
      throw new Error('Cannot run electrostim program without EMS Feature');
    }
    this.currentProgramDuration.value = this._emsFeature.progress();
    this.currentRepetitionDuration.value = this._emsFeature.repetitionProgress();
    this.currentPhaseDuration.value = this._emsFeature.phaseProgress();
  }
}
