import { BaseTimeLinePoint, ChannelIndex, ChannelMap } from './types';

export class BaseChartTimelineGenerator<T extends BaseTimeLinePoint = BaseTimeLinePoint> {
  protected _timelines: ChannelMap<T[]> = new ChannelMap<T[]>();

  get timelines() {
    return this._timelines;
  }

  constructor() {}
  dispose() {}

  getTimelineValue(channelIndex: ChannelIndex, time: number) {
    if (!this._timelines.channelExists(channelIndex)) {
      return null;
    }
    const index = this.findIndexOfPoint(time, channelIndex);
    if (!index) {
      return null;
    }

    const prevPoint = this._timelines.get(channelIndex)[index - 1];
    const currPoint = this._timelines.get(channelIndex)[index];

    const dy = currPoint.value - prevPoint.value;
    const dx = currPoint.time - prevPoint.time;

    const px = time - prevPoint.time;
    const py = dx === 0 ? prevPoint.value : (dy / dx) * px;

    return py + prevPoint.value;
  }

  private binarySearch(data: T[], time: number, fn: (data: T[], index: number, time: number) => number) {
    let minIndex = 0;
    let maxIndex = data.length - 1;

    while (maxIndex >= minIndex) {
      const index = Math.floor((maxIndex - minIndex) / 2) + minIndex;
      const result = fn(data, index, time);
      if (!result) {
        return index;
      }
      if (result < 0) {
        maxIndex = index - 1;
      } else {
        minIndex = index + 1;
      }
    }
    return null;
  }

  private findIndexOfPoint(time: number, channel: ChannelIndex) {
    if (!this._timelines.get(channel)) {
      return null;
    }
    const timeline = this._timelines.get(channel) ?? [];

    const index = this.binarySearch(timeline, time, (data, index, time) => {
      return time < data[index > 0 ? index - 1 : 0].time ? -1 : time > data[index].time ? 1 : 0;
    });
    return index;
  }
}
